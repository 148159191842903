import React from 'react';

function HomeSEO() {
  return (
    <meta
      name="description"
      content="Closure ontlast nabestaanden bij de administratieve handelingen rondom het opzeggen en overzetten van contracten na een overlijden. Hierbij leveren wij first-time-right meldingen aan service providers en besparen hierbij op operationele kosten."
    />
  );
}

function HomeSEOFRBE() {
  return (
    <meta
      name="description"
      content="La fermeture soulage les proches des tâches administratives liées à la résiliation et au transfert des contrats après un décès. Nous fournissons des notifications immédiates aux fournisseurs de services et économisons sur les coûts opérationnels."
    />
  );
}

export { HomeSEO, HomeSEOFRBE };
