import React, { useEffect, useState } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { corporatesBlue, corporatesWhite, mediaColor, uoColor } from '../../content/data/partners';

export interface PartnersProps {
  variant?: 'media' | 'corporate' | 'uitvaartondernemers';
  color?: 'color' | 'white' | 'blue';
}

function Partners({ variant = 'corporate', color = 'white' }: PartnersProps) {
  const [domLoaded, setDomLoaded] = useState(false);
  let companies: { id: number; logo: JSX.Element }[] = [];
  if (variant === 'corporate' && color === 'white') {
    companies = corporatesWhite;
  } else if (variant === 'corporate' && color === 'blue') {
    companies = corporatesBlue;
  } else if (variant === 'uitvaartondernemers') {
    companies = uoColor;
  } else if (variant === 'media') {
    companies = mediaColor;
  }
  useEffect(() => {
    setDomLoaded(true);
  }, []);
  if (domLoaded) {
    return (
      <Swiper
        modules={[Autoplay]}
        spaceBetween={10}
        loop
        autoplay={{
          delay: 2000
        }}
        speed={4000}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20
          }
        }}
      >
        {companies.map(company => (
          <SwiperSlide className="flex items-center justify-center" key={company.id}>
            {company.logo}
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
  return null;
}
export default Partners;
