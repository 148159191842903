import React from 'react';

export interface TestimonialProps {
  img: JSX.Element;
  name: string;
  jobFunction: string;
  body: string;
}

function TestimonialCard({ img, name, jobFunction, body }: TestimonialProps) {
  return (
    <div className="h-96 rounded-xl bg-offwhite">
      <div className="flex items-center px-6 pt-6">
        <div className="h-12 w-12 overflow-hidden rounded-full">{img}</div>
        <div className="ml-4">
          <div className="text-lg text-blue">{name}</div>
          <div className="text-plum">{jobFunction}</div>
        </div>
      </div>
      <div className="p-6 text-lg text-blue">{body}</div>
    </div>
  );
}

export default TestimonialCard;
