import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

export interface CaseStudyCardProp {
  id: number;
  img: string | JSX.Element;
  company: string;
  description: string | JSX.Element;
  url: string;
  cases: string;
}

export default function CaseStudyCard({
  id,
  img,
  company,
  description,
  url,
  cases
}: CaseStudyCardProp) {
  return (
    <div key={id} className="space-y-4 rounded-b-lg bg-white">
      <div className="flex flex-col justify-between">
        {img}
        <div className="py-10 px-8">
          <div className="mb-2 text-lg text-blue">{company}</div>
          <div className="mb-2 inline-block rounded-full bg-darkwhite py-1 px-3 text-xs font-medium text-darkblue">
            {cases} <FormattedMessage id="notificationsCaseStudy" />
          </div>

          <p className="mb-4 text-base text-plum">{description}</p>
          <Button type="primary" color="blue" link={url} variant="link">
            <FormattedMessage id="readmore" />
          </Button>
        </div>
      </div>
    </div>
  );
}
