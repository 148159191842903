import { HeadProps } from 'gatsby';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FooterCTA from '../../components/FooterCTA';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import Title from '../../components/Typography/Title';
import VacancyCard from '../../components/VacancyCard';
import { vacancies } from '../../content/data/vacatures';
import { VacaturesSEO, VacaturesSEOFRBE } from '../../content/seo/vacatures';
import HeadParent from '../../utils/header';

function Vacancy({ pageContext }) {
  const { locale } = pageContext;
  return (
    <Layout pageContext={pageContext} navColor="bg-darkwhite" navTextColor="dark">
      <Section paddingBottom="pb-8 lg:pb-12" paddingTop="pt-8 sm:pt-12" bgColor="bg-darkwhite">
        <div className="mx-auto max-w-7xl">
          <div className="pb-12 text-center lg:pb-24">
            <Title color="dark">
              <FormattedMessage id="vacatures" />
            </Title>
          </div>
        </div>
      </Section>
      <Section paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="mx-auto max-w-3xl space-y-8">
          {/* {locale === 'fr-BE' &&
            vacanciesFRBE.map(jobListing => <VacancyCard key={jobListing.id} {...jobListing} />)} */}
          {(locale === 'nl-NL' || locale === 'nl-BE') &&
            vacancies.map(jobListing => <VacancyCard key={jobListing.id} {...jobListing} />)}
        </div>
      </Section>
      <FooterCTA bgColor="bg-darkwhite" bgInsideColor="bg-darkwhite" />
    </Layout>
  );
}

export default Vacancy;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  let pageTitle = '';
  switch (locale) {
    case 'nl-NL':
      pageTitle = 'Vacatures';
      break;
    case 'fr-BE':
      pageTitle = 'Postes vacants';
      break;
    case 'nl-BE':
      pageTitle = 'Vacatures';
      break;
    default:
      pageTitle = 'Vacatures';
      break;
  }
  return (
    <HeadParent pageTitle={pageTitle} location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <VacaturesSEO />}
      {locale === 'fr-BE' && <VacaturesSEOFRBE />}
    </HeadParent>
  );
}
