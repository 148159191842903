import React from 'react';

import Section from '../Section';

export interface SimpleHeaderProps {
  title: string | JSX.Element;
  lastUpdated: string | JSX.Element;
}

export default function SimpleHeader({ title, lastUpdated }: SimpleHeaderProps) {
  return (
    <Section paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28" bgColor="bg-darkwhite">
      <div className="space-y-8 px-8">
        <div className="text-left font-header text-5xl text-blue">{title}</div>
        <p className="text-lg text-plum">{lastUpdated}</p>
      </div>
    </Section>
  );
}
