import React from 'react';

import { classNames } from '../../utils/helpers';

export interface SectionProps {
  bgColor?: string;
  paddingTop?: string;
  paddingBottom?: string;
  children: React.ReactNode;
  bgImage?: string;
}
function Section({
  bgColor = 'bg-offwhite',
  paddingTop = 'pt-0 lg:pt-0',
  paddingBottom = 'pb-0 lg:pb-0',
  children,
  bgImage
}: SectionProps) {
  const baseStyles = 'overflow-hidden relative';
  return (
    <div
      className={classNames(
        baseStyles,
        bgColor,
        bgImage ? `${bgImage} bg-cover` : '',
        paddingTop,
        paddingBottom
      )}
    >
      <div className="mx-auto max-w-7xl px-4 lg:px-4">{children}</div>
    </div>
  );
}

export default Section;
