import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const generalUsps = [
  {
    id: 1,
    title: 'Verlaag de backoffice kosten aanzienlijk',
    description:
      'De backoffice kosten binnen jullie organisatie worden met 70% verlaagd, omdat de noodzaak voor het opvragen, valideren en communiceren van documenten en persoonsgegevens wegvalt.'
  },
  {
    id: 2,
    title: 'Vermijd overbodig papierwerk',
    description:
      'Complexe processen, zoals het overnemen van een contract van de overledene, vragen vaak om veel aanvullende documentatie en formulieren. Closure vraagt slechts één keer om gegevens, waardoor de nabestaanden worden ontlast van al dit repetitieve papierwerk. '
  },
  {
    id: 3,
    title: 'Oplossing voor alle kanalen',
    description:
      'We bieden zowel een digitale als een telefonische oplossing voor het afronden van abonnementen namens jullie organisatie. Hierdoor zijn wij in staat om alle klanten, van jong tot oud, op een efficiënte en klantvriendelijke manier te helpen.'
  }
];

const generalUspsFRBE = [
  {
    id: 1,
    title: 'Réduisez considérablement les coûts de la back-office',
    description:
      'Les coûts de la back-office dans votre organisation sont réduits de 70% car la nécessité de demander, valider et communiquer des documents et des informations personnelles est éliminée.'
  },
  {
    id: 2,
    title: 'Évitez les paperasses inutiles',
    description:
      "Des processus complexes, tels que la prise en charge d'un contrat d'une personne décédée, nécessitent souvent de nombreux documents supplémentaires et formulaires. Closure ne demande les informations qu'une seule fois, soulageant ainsi les proches de tout ce travail administratif répétitif."
  },
  {
    id: 3,
    title: 'Solution pour tous les canaux',
    description:
      'Nous offrons une solution digitale et téléphonique pour clôturer les abonnements pour votre organisation. Cela nous permet de fournir une aide efficace et amicale à tous les clients, jeunes et vieux.'
  }
];

const generalCustomerUsps = [
  {
    id: 1,
    title: 'Klantvriendelijk proces',
    description:
      'Closure biedt altijd first-time-right verzoeken aan, wat resulteert in een snelle afwikkeling van de abonnementen. Zo kunnen aanvragen die normaal gesproken maanden kunnen duren door Closure binnen een aantal werkdagen worden verwerkt.'
  },
  {
    id: 2,
    title: 'Minder emotioneel beladen contactmomenten',
    description:
      'Voor nabestaanden kan het lastig zijn om met meerdere organisaties tegelijkertijd in contact te zijn over het afwikkelen van abonnementen. Bij Closure is er slechts één contactmoment nodig met de nabestaanden.'
  },
  {
    id: 3,
    title: 'Continu betrokken bij het proces',
    description:
      'Door ons gebruiksvriendelijke dashboard hebben de nabestaanden altijd inzicht in de actuele status van de afwikkeling van abonnementen. '
  },
  {
    id: 4,
    title: 'De mogelijkheid om abonnementen bij meerdere organisaties in een keer af te wikkelen',
    description:
      'Met onze oplossing kunnen nabestaanden bij meer dan 1600 organisaties een abonnement opzeggen, wijzigen of overdragen. Dit wordt hen mogelijk gemaakt aan de hand van ons white label platform die jullie zelf als organisatie aan de nabestaanden kunnen aanbieden.'
  }
];

const generalCustomerUspsFRBE = [
  {
    id: 1,
    title: 'Processus client-friendly',
    description:
      'Closure offre toujours des demandes correctes du premier coup, ce qui entraîne un traitement rapide des abonnements. Ainsi, les demandes qui peuvent normalement prendre des mois peuvent être traitées par Closure en quelques jours.'
  },
  {
    id: 2,
    title: 'Moins de moments de contact émotionnellement chargés',
    description:
      "Pour les proches, il peut être difficile de contacter plusieurs organisations en même temps pour clôturer les abonnements. Avec Closure, il n'y a qu'un seul moment de contact nécessaire avec les proches."
  },
  {
    id: 3,
    title: 'Implication continue dans le processus',
    description:
      "Grâce à notre tableau de bord facile à utiliser, les proches ont toujours une vue sur l'état actuel de la clôture des abonnements."
  },
  {
    id: 4,
    title:
      'La possibilité de clôturer des abonnements chez plusieurs organisations en une seule fois',
    description:
      "Avec notre solution, les proches peuvent annuler, modifier ou transférer un abonnement auprès de plus de 1600 organisations. Cela leur est possible grâce à notre plateforme white label que vous, en tant qu'organisation, pouvez offrir aux proches."
  }
];
const USP = {
  TELECOM: {
    id: 1,
    company: [
      {
        id: 4,
        title: 'Voorzie nabestaanden van een opzegdienst die ze waarderen',
        description:
          'Nabestaanden worden in staat gesteld om niet alleen een account op te zeggen, maar ook een account te wijzigen of over te dragen. Hierdoor kunnen nabestaanden bijvoorbeeld een telefoonnummer behouden als prepaid of het contract van de overledene overnemen.'
      },
      ...generalUsps
    ],
    heirs: [...generalCustomerUsps]
  },
  NONPROFIT: {
    id: 2,
    company: [
      {
        id: 4,
        title: 'Stel nabestaanden in staat om een laatste schenking te doen',
        description:
          'Wanneer een overledene voor een lange tijd geld heeft gedoneerd aan uw non-profit organisatie,  vinden de nabestaanden vaak troost bij het doen van een laatste donatie ter nagedachtenis aan de overledene.'
      },
      ...generalUsps
    ],
    heirs: [...generalCustomerUsps]
  },
  FINANCE: {
    id: 3,
    company: [
      {
        id: 4,
        title: 'Geef nabestaanden inzicht tijdens het gehele proces.',
        description:
          'Voor veel nabestaanden is het een complex proces om zelf financiële instanties op de hoogte te stellen van een overlijden. Bij Closure kunnen nabestaanden op elk moment de status van het verzoek inzien op het white label platform.'
      },
      {
        id: 5,
        title: 'Zet de communicatie tussen meerdere gezinsleden centraal',
        description:
          'Om financiële instellingen op de hoogte stellen van een overlijden, moeten nabestaanden vaak veel documenten en formulieren aanleveren. Closure vermindert deze documentatie aanzienlijk, waardoor de kosten worden verlaagd en de ervaring voor nabestaanden wordt verbeterd.'
      },
      ...generalUsps
    ],
    heirs: [...generalCustomerUsps]
  },
  MEDIA: {
    id: 4,
    company: [...generalUsps],
    heirs: [...generalCustomerUsps]
  },
  UTILITIES: {
    id: 5,
    company: [
      {
        id: 6,
        title: 'Voor elke situatie een oplossing',
        description:
          ' Een overlijdensgeval kan voor een energie- of waterlevering een complexe case zijn; het huis wordt tijdelijk niet bewoond, het wordt geërfd, verkocht of de huur wordt opgezegd. Closure faciliteert deze processen en helpt nabestaanden bij het correct aanleveren van gegevens zoals meterstanden.'
      },
      ...generalUsps
    ],
    heirs: [...generalCustomerUsps]
  }
};

const USPFRBE = {
  TELECOM: {
    id: 1,
    company: [
      {
        id: 4,
        title: 'Fournir des services de résiliation appréciés aux ayants droit',
        description:
          'Les ayants droit peuvent non seulement résilier un compte, mais également le modifier ou le transférer. Cela permet aux ayants droit, par exemple, de conserver un numéro de téléphone en tant que prépayé ou de prendre en charge le contrat de la personne décédée.'
      },
      ...generalUspsFRBE
    ],
    heirs: [...generalCustomerUspsFRBE]
  },
  NONPROFIT: {
    id: 2,
    company: [
      {
        id: 4,
        title: 'Permettre aux ayants droit de faire un dernier don',
        description:
          "Lorsqu'une personne décédée a fait des dons à votre organisation à but non lucratif pendant une longue période, les ayants droit trouvent souvent réconfort à faire un dernier don en souvenir de la personne décédée."
      },
      ...generalUspsFRBE
    ],
    heirs: [...generalCustomerUspsFRBE]
  },
  FINANCE: {
    id: 3,
    company: [
      {
        id: 4,
        title: 'Donner aux ayants droit une visibilité tout au long du processus',
        description:
          "Pour de nombreux ayants droit, il est un processus complexe pour informer les institutions financières d'un décès. Avec Closure, les ayants droit peuvent consulter le statut de la demande à tout moment sur la plateforme white label."
      },
      {
        id: 5,
        title: 'Mettre la communication entre plusieurs membres de la famille au centre',
        description:
          "Pour informer les institutions financières d'un décès, les ayants droit doivent souvent fournir de nombreux documents et formulaires. Closure réduit considérablement cette documentation, ce qui réduit les coûts et améliore l'expérience pour les ayants droit."
      },
      ...generalUspsFRBE
    ],
    heirs: [...generalCustomerUspsFRBE]
  },
  MEDIA: {
    id: 4,
    company: [...generalUspsFRBE],
    heirs: [...generalCustomerUspsFRBE]
  },
  UTILITIES: {
    id: 5,
    company: [
      {
        id: 6,
        title: 'Une solution pour chaque situation',
        description:
          "Un décès peut être un cas complexe pour la fourniture d'énergie ou d'eau; la maison est temporairement inhabitée, elle est héritée, vendue ou la location est résiliée. Closure facilite ces processus et aide les"
      },
      ...generalUspsFRBE
    ],
    heirs: [...generalCustomerUspsFRBE]
  }
};

const caseStudies = [
  {
    id: 1,
    title: "Hoe Closure helpt bij Odido's overlijdensmeldingen",
    description: <FormattedMessage id="tMobileCaseStudySummary" />,
    stat1: {
      text: 'Meldingen verwerkt',
      value: '20.000+'
    },
    img: (
      <StaticImage
        src="../../images/case-studies/t-mobile/case-study-t-mobile-header.png"
        alt="Odido case study"
        className="w-full"
      />
    ),
    link: '/case-studies/t-mobile'
  },
  {
    id: 2,
    title: 'Het afwikkelen van de abonnementen bij OV-chipkaart',
    description:
      '‘’Wij voelen ons verantwoordelijk om ook nabestaanden zo goed mogelijk van dienst te zijn‘’ - Jokelien Vles, Adviseur Reizigerscommunicatie',
    stat1: {
      text: 'Meldingen verwerkt',
      value: '10.000+'
    },
    img: (
      <StaticImage
        src="../../images/case-studies/t-mobile/case-study-t-mobile-header.png"
        alt="Odido case study"
        className="w-full"
      />
    ),
    link: '/case-studies/ov-chipkaart'
  }
];

const caseStudiesFRBE = [
  {
    id: 1,
    title: 'Comment Closure aide à la notification de décès chez Odido',
    description: <FormattedMessage id="tMobileCaseStudySummary" />,
    stat1: {
      text: 'Notifications traitées',
      value: '20.000+'
    },
    img: (
      <StaticImage
        src="../../images/case-studies/t-mobile/case-study-t-mobile-header.png"
        alt="Odido case study"
        className="w-full"
      />
    ),
    link: '/case-studies/t-mobile'
  },
  {
    id: 2,
    title: 'Clôture des abonnements chez OV-chipkaart',
    description:
      "'Nous nous sentons responsables de fournir un service aussi bon que possible aux proches' - Jokelien Vles, Conseillère Communication Voyageurs",
    stat1: {
      text: 'Notifications traitées',
      value: '10.000+'
    },
    img: (
      <StaticImage
        src="../../images/case-studies/t-mobile/case-study-t-mobile-header.png"
        alt="Odido case study"
        className="w-full"
      />
    ),
    link: '/case-studies/ov-chipkaart'
  }
];

const faqs = [
  {
    id: 1,
    question: 'Hoe weet ik welke abonnementen er opgezegd of overgenomen moeten worden?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat."
  },
  {
    id: 2,
    question:
      'Hoeveel tijd neemt het in beslag voordat alle abonnementen zijn opgezegd en overgenomen?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat."
  },
  {
    id: 3,
    question: 'Hoe krijg ik toegang tot de rekening van de overleden persoon?',
    answer:
      'Selecteer in de checklist de betreffende bank. Closure zal de bank vervolgens informeren over het overlijden. De bank zal daarna contact met je opnemen om samen te kijken welke documenten er nodig zijn om jou toegang te kunnen verlenen tot de rekening.'
  },
  {
    id: 4,
    question:
      'Wat moet mijn relatie tot de overleden persoon zijn om gebruik te maken van Closure?',
    answer:
      'Je kunt Closure gebruiken als je verantwoordelijk bent voor de afhandeling van de erfenis. Dit kan zijn wanneer je bent benoemd tot executeur (testamentair) of als je (door bijvoorbeeld de nabestaanden) bent aangewezen om dit regelen.'
  },
  {
    id: 5,
    question:
      'Wat moet mijn relatie tot de overleden persoon zijn om gebruik te maken van Closure?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat."
  },
  {
    id: 6,
    question:
      'Welke gegevens en documenten heb ik nodig om te beginnen met het opzeggen, wijzigen of overdragen van abonnementen?',
    answer:
      'Als nabestaande heb je enkel de persoonsgegevens van de overledene en een akte van overlijden nodig. De akte van overlijden krijg je meestal van de uitvaartondernemer en anders is deze ook verkrijgbaar bij de gemeente waar de persoon is overleden. Heb je op dit moment de akte van overlijden nog niet bij de hand? Dan kan je die ook op een later moment aan jouw account toevoegen.'
  },
  {
    id: 7,
    question:
      'Wat moet mijn relatie tot de overleden persoon zijn om gebruik te maken van Closure?',
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat."
  }
];

const industries = [
  { id: 1, type: 'TELECOM', text: 'Telecom' },
  { id: 2, type: 'NONPROFIT', text: 'Non-Profit' },
  { id: 3, type: 'FINANCE', text: 'Finance' },
  { id: 4, text: 'Media', type: 'MEDIA' },
  { id: 5, text: 'Utilities', type: 'UTILITIES' }
];

const integrationTypes = [
  {
    id: 1,
    title: <FormattedMessage id="uspTypeIntegrationLowTitle" />,
    body: <FormattedMessage id="uspTypeIntegrationLowBody" />,
    img: (
      <StaticImage
        src="../../images/company-low-impact-it.png"
        alt="zero impact"
        placeholder="none"
      />
    )
  },
  {
    id: 2,
    title: <FormattedMessage id="uspTypeIntegrationMediumTitle" />,
    body: <FormattedMessage id="uspTypeIntegrationMediumBody" />,
    img: (
      <StaticImage
        src="../../images/company-medium-impact-it.png"
        alt="medium impact"
        placeholder="none"
      />
    )
  },
  {
    id: 3,
    title: <FormattedMessage id="uspTypeIntegrationHighTitle" />,
    body: <FormattedMessage id="uspTypeIntegrationHighBody" />,
    img: (
      <StaticImage
        src="../../images/company-high-impact-it.png"
        alt="high impact"
        placeholder="none"
      />
    )
  }
];

const integrationTypesFRBE = [
  {
    id: 1,
    title: <FormattedMessage id="uspTypeIntegrationLowTitle" />,
    body: <FormattedMessage id="uspTypeIntegrationLowBody" />,
    img: (
      <StaticImage
        src="../../images/company-low-impact-it-frbe.png"
        alt="zero impact"
        placeholder="none"
      />
    )
  },
  {
    id: 2,
    title: <FormattedMessage id="uspTypeIntegrationMediumTitle" />,
    body: <FormattedMessage id="uspTypeIntegrationMediumBody" />,
    img: (
      <StaticImage
        src="../../images/company-medium-impact-it-frbe.png"
        alt="medium impact"
        placeholder="none"
      />
    )
  },
  {
    id: 3,
    title: <FormattedMessage id="uspTypeIntegrationHighTitle" />,
    body: <FormattedMessage id="uspTypeIntegrationHighBody" />,
    img: (
      <StaticImage
        src="../../images/company-high-impact-it-frbe.png"
        alt="high impact"
        placeholder="none"
      />
    )
  }
];

const testimonials = [
  {
    id: 1,
    name: 'Bas Jansen',
    jobFunction: 'Manager Operations bij Odido',
    body: 'We zien dat we betere kwaliteit krijgen aangeleverd nu de melding via Closure loopt […] We hoeven niet terug naar de nabestaande om te vragen of ze nóg een keer die ID-kaart willen sturen, of betere scan willen maken van de overlijdensakte.',
    img: (
      <StaticImage
        src="../../images/testimonials/bas-jansen-256x256.png"
        alt="Bas Jansen Odido Closure Testimonial"
      />
    )
  },
  {
    id: 2,
    name: 'Sandra Swinkels',
    jobFunction: 'Nabestaande van Odido klant',
    body: 'De opzegdienst waar ik via Odido gebruik van kon maken, heeft mij enorm veel werk uit handen genomen en stress bespaard. Dankzij de regelmatige terugkoppeling was ik altijd op de hoogte en ik werd bij vragen altijd vriendelijk te woord gestaan.',
    img: (
      <StaticImage
        src="../../images/testimonials/sandra-256x256.png"
        alt="Sandra swinkels closure testimonial"
        imgStyle={{ borderRadius: '256px' }}
      />
    )
  }
];

const testimonialsFRBE = [
  {
    id: 1,
    name: 'Bas Jansen',
    jobFunction: 'Manager des opérations chez Odido',
    body: "Nous constatons que nous obtenons une meilleure qualité de déclaration maintenant que la déclaration passe par Closure [...] Nous n'avons pas besoin de retourner à la proche parent pour demander s'ils veulent envoyer à nouveau la carte d'identité ou faire une meilleure numérisation de l'acte de décès.",
    img: (
      <StaticImage
        src="../../images/testimonials/bas-jansen-256x256.png"
        alt="Témoignage de Bas Jansen de Odido Closure"
      />
    )
  },
  {
    id: 2,
    name: 'Sandra Swinkels',
    jobFunction: "Proche parent d'un client Odido",
    body: "Le service de résiliation que je pouvais utiliser via Odido m'a énormément facilité la vie et économisé de l'angoisse. Grâce aux retours réguliers, j'étais toujours informé et j'ai toujours été accueilli avec amabilité en cas de questions.",
    img: (
      <StaticImage
        src="../../images/testimonials/sandra-256x256.png"
        alt="Témoignage de Sandra de Closure"
        imgStyle={{ borderRadius: '256px' }}
      />
    )
  }
];

export {
  USP,
  USPFRBE,
  caseStudiesFRBE,
  testimonialsFRBE,
  integrationTypesFRBE,
  industries,
  faqs,
  caseStudies,
  integrationTypes,
  testimonials
};
