import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface VacancyProps {
  title: string;
  description: string;
  url: string;
}

export default function VacancyCard({ title, description, url }: VacancyProps) {
  return (
    <div className="w-full rounded-xl border border-gray-100 py-4 px-8">
      <div className="flex items-center justify-between">
        <div className="">
          <div className="mb-2 text-xl font-medium text-darkblue">{title}</div>
          <div className="text-lg text-gray-600">{description}</div>
        </div>
        <a href={url} className="rounded-md bg-gray-200 py-2 px-5 text-gray-500">
          <FormattedMessage id="viewButton" />
        </a>
      </div>
    </div>
  );
}