/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const faqs = [
  {
    id: 1,
    question: 'Wat maakt onze service uniek?',
    answer:
      'Met de service van NabestaandenLoket worden naast een complete checklist en afscheidspagina ook contracten, abonnementen en (online) accounts voor nabestaanden opgezegd, gewijzigd of overgedragen. Het is een complete service, met een dagelijkse bereikbaarheid via de telefoon en de chat. Doordeweeks is NabestaandenLoket bereikbaar tussen 08:30 uur en 21:00 uur en op zaterdag tussen 09:00 uur en 21:00 uur.'
  },
  {
    id: 2,
    question: 'Wat is verschil tussen Closure en Nabestaandenloket?',
    answer:
      "Closure verzorgt enkel het opzeggen, wijzigen en overdragen van contracten, accounts en abonnementen. NabestaandenLoket heeft naast de afwikkeling van abonnementen ook een persoonlijke checklist met alle zaken die op het pad van nabestaanden komen. Zo heeft NabestaandenLoket een afscheidspagina met alle relevante informatie over de uitvaart en een herdenkingspagina waar condoleanceberichten en foto's geplaatst kunnen worden."
  },
  {
    id: 3,
    question: 'Hoe zie ik dat er iets is opgezegd is?',
    answer:
      'Op het persoonlijke account kunnen nabestaanden een actueel statusoverzicht zien met alle opzeggingen, wijzigingen en overdragingen van contracten, accounts en abonnementen.'
  },
  {
    id: 4,
    question: 'Ik wil Nabestaandenloket aan mijn nabestaanden aanbieden, en nu?',
    answer:
      'Via de website van NabestaandenLoket kun je een kennismakingsgesprek met ons inplannen. Wij leggen je in dit gesprek graag uit hoe het platform werkt en hoe NabestaandenLoket een toegevoegde waarde kan zijn voor jouw uitvaartonderneming. '
  },
  {
    id: 5,
    question: 'Hoe krijg ik Nabestaandenloket op mijn website?',
    answer: 'Je ontvangt van ons een unieke url, welke je aan je eigen website kunt koppelen.'
  },
  {
    id: 6,
    question: 'Hoelang blijft een account van mijn nabestaanden actief?',
    answer:
      'Totdat alles afgewikkeld is. De nabestaanden hebben geen tijdsdruk of deadline. Wij helpen de nabestaanden totdat alles 100% afgerond is.'
  },
  {
    id: 7,
    question: 'Werken jullie landelijk?',
    answer:
      'Wij werken zowel landelijk, als internationaal. Recent zijn wij in België met de service gestart.'
  }
];

const benefits = [
  {
    id: 1,
    title: <FormattedMessage id="uoSecondaryBenefits1Title" />,
    body: <FormattedMessage id="uoSecondaryBenefits1Body" />
  },
  {
    id: 2,
    title: <FormattedMessage id="uoSecondaryBenefits2Title" />,
    body: <FormattedMessage id="uoSecondaryBenefits2Body" />
  },
  {
    id: 3,
    title: <FormattedMessage id="uoSecondaryBenefits3Title" />,
    body: <FormattedMessage id="uoSecondaryBenefits3Body" />
  },
  {
    id: 4,
    title: <FormattedMessage id="uoSecondaryBenefits4Title" />,
    body: <FormattedMessage id="uoSecondaryBenefits4Body" />
  },
  {
    id: 5,
    title: <FormattedMessage id="uoSecondaryBenefits5Title" />,
    body: <FormattedMessage id="uoSecondaryBenefits5Body" />
  },
  {
    id: 6,
    title: <FormattedMessage id="uoSecondaryBenefits6Title" />,
    body: <FormattedMessage id="uoSecondaryBenefits6Body" />
  },
  {
    id: 7,
    title: <FormattedMessage id="uoSecondaryBenefits7Title" />,
    body: <FormattedMessage id="uoSecondaryBenefits7Body" />
  },
  {
    id: 8,
    title: <FormattedMessage id="uoSecondaryBenefits8Title" />,
    body: <FormattedMessage id="uoSecondaryBenefits8Body" />
  },
  {
    id: 9,
    title: <FormattedMessage id="uoSecondaryBenefits9Title" />,
    body: <FormattedMessage id="uoSecondaryBenefits9Body" />
  }
];

export { faqs, benefits };
