import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LocalizedLink } from 'gatsby-plugin-i18n-l10n';
import CookiesConsent from 'react-cookie-consent';

import { navItems } from '../../../content/data/navigation';
import { ReactComponent as LogoGreen } from '../../../images/svg/green.svg';
// import Button from '../../Button';
import Icon from '../../Icon';
import { getFeatureFlags } from '../../../utils/helpers';

// import ModalNewsletter from '../../ModalNewsletter';

function Footer({ pageContext }) {
  const featureFlags = getFeatureFlags(pageContext.locale);
  const intl = useIntl();
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-darkwhite">
      <div className="container mx-auto max-w-7xl px-4 py-16 lg:py-16 ">
        <div className="flex grid-flow-col flex-col md:grid md:gap-8">
          <div className="grid grid-flow-row grid-cols-2 gap-y-10 gap-x-10 lg:grid-flow-col lg:grid-cols-4">
            {navItems.map(navItemGroup => (
              <div className="pb-2" key={navItemGroup.id}>
                <div className="pb-2 text-lg font-medium text-darkblue">
                  {navItemGroup.placeholder}
                </div>
                {navItemGroup.navItems.map(navItem => {
                  if (
                    navItem.url === 'uitvaart-ondernemers' &&
                    featureFlags['uitvaart-ondernemers'] === false
                  ) {
                    return null;
                  }
                  if (navItem.url === 'case-studies' && featureFlags['case-studies'] === false) {
                    return null;
                  }
                  return (
                    <LocalizedLink
                      key={navItem.id}
                      to={intl.formatMessage({ id: `url.${navItem.url}` })}
                      className="flex h-10 flex-row items-center text-sm text-plum hover:text-darkblue-200 lg:text-base"
                    >
                      {navItem.name}
                    </LocalizedLink>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-28 grid grid-flow-row flex-col items-center sm:grid-flow-col sm:grid-cols-2 md:grid md:gap-8">
          <div className="col-span-1">
            <LogoGreen className="mb-2 h-4 w-auto md:h-6" />
            <span className="text-xs font-normal text-blue">
              <FormattedMessage id="copyright" />
            </span>
          </div>
          <div className="col-span-1 mt-10 flex items-center justify-start space-x-4 sm:mt-0 sm:justify-end">
            <Icon variant="linkedin" />
            {/* <Button type="primary" variant="modal" color="blue" modal={() => setIsOpen(true)}>
              <FormattedMessage id="newsletter" />
            </Button> */}
          </div>
        </div>
      </div>
      <div className="bg-blue">
        <div className="py-3 px-6 text-center text-xs text-white sm:text-sm">
          <p>
            <FormattedMessage id="afm" />
          </p>
        </div>
      </div>
      {/* <ModalNewsletter isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      <CookiesConsent
        location="bottom"
        buttonText={intl.formatMessage({ id: `accept` })}
        declineButtonText={intl.formatMessage({ id: `decline` })}
        containerClasses="container-cookie"
        disableStyles
        flipButtons
        style={{}}
        buttonStyle={{
          textAlign: 'center',
          background: '#1C4044',
          color: '#FFFBF5',
          padding: '8px 20px',
          borderRadius: '5px',
          marginRight: '14px'
        }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        <div className="mx-auto mr-0 max-w-4xl text-left text-xs text-plum lg:mr-8">
          { intl.formatMessage({ id: `cookieBanner` }) }{' '}
          <span className="font-medium text-primary underline">
            <LocalizedLink to="/cookies">{ intl.formatMessage({ id: `privacyPolicy` }) }</LocalizedLink>.
          </span>
        </div>
      </CookiesConsent>
    </div>
  );
}

export default Footer;
