import React from 'react';

import { ReactComponent as LinkedinLogo } from '../../images/svg/linkedin-icon.svg';

export interface IconProps {
  variant: 'linkedin';
}

function Icon({ variant }: IconProps) {
  if (variant === 'linkedin') {
    return (
      <a href="https://www.linkedin.com/company/closure_services/">
        <div className="flex h-10 w-10 items-center rounded-full bg-blue p-3">
          <LinkedinLogo />
        </div>
      </a>
    );
  }
  return null;
}

export default Icon;
