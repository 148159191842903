import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { classNames } from '../../utils/helpers';
import Button from '../Button';
import ModalDemo from '../ModalDemo';
import Section from '../Section';

export interface FooterProps {
  bgColor: string;
  bgInsideColor?: string;
}

export default function FooterCTA({ bgColor, bgInsideColor = bgColor }: FooterProps) {
  const [isOpen, setIsOpen] = useState(false);

  const modalOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Section bgColor={bgColor} paddingTop="pt-12 lg:pt-28">
      <div
        className={classNames(bgInsideColor || 'bg-offwhite', 'rounded-2xl p-8 lg:py-12 lg:px-10')}
      >
        <div className="mx-auto max-w-2xl text-left lg:text-center">
          <div className="mb-8 font-header text-3xl text-blue lg:text-4xl">
            <FormattedMessage id="companyFooterCtaTitle" />
          </div>
          <p className="mb-8 text-base text-blue">
            <FormattedMessage id="companyFooterCtaBody" />
          </p>
          <Button type="primary" variant="modal" modal={modalOpen} color="blue">
            <FormattedMessage id="companyFooterCtaButton" />
          </Button>
        </div>
      </div>
      <ModalDemo isOpen={isOpen} setIsOpen={setIsOpen} />
    </Section>
  );
}
