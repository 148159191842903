import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { Dialog, Transition } from '@headlessui/react';

import Button from '../Button';

export interface ModalUnofficialPartnerProps {
  isOpen: boolean;
  setIsOpen: Function;
}
export default function ModalUnofficialPartnerInformation({
  isOpen,
  setIsOpen
}: ModalUnofficialPartnerProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-lg transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:align-middle md:h-auto">
                <div className="h-full">
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-plum hover:text-plum focus:outline-none"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="mx-auto flex max-w-md flex-col py-12 px-6 md:py-20 lg:py-28">
                    <Dialog.Title as="h3" className="font-header text-2xl text-blue">
                      <FormattedMessage id="partnersModalInfoTitle" />
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className=" text-base text-gray-500">
                        <FormattedMessage id="partnersModalInfoBody" />
                      </p>
                    </div>
                    <div className="mt-8 ">
                      <Button
                        type="primary"
                        color="blue"
                        variant="modal"
                        modal={() => window.location.reload()}
                      >
                        <FormattedMessage id="officialPartners" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
