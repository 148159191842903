import React from 'react';

export interface StepCardProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  img: JSX.Element;
}

export default function StepCard({ title, description, img }: StepCardProps) {
  return (
    <div className="flex flex-col justify-start space-y-4 rounded-xl bg-offwhite p-8 lg:items-start lg:p-14 lg:text-left">
      {img}
      <div className="text-2xl text-blue lg:text-xl">{title}</div>
      <p className="text-base text-plum">{description}</p>
    </div>
  );
}
