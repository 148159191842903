import React from 'react';

export interface TeamCardProps {
  img: JSX.Element;
  name: string;
  jobFunction: string;
}

export default function TeamCard({ img, name, jobFunction }: TeamCardProps) {
  return (
    <div className="flex flex-col items-center justify-center rounded-xl bg-white p-10">
      <div className="overflow-hidden rounded-full">{img}</div>

      <div className="text-center">
        <span className="text-lg font-medium text-blue">{name}</span>
        <p className="text-base text-plum">{jobFunction}</p>
      </div>
    </div>
  );
}
