function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const TYPECLIENT = {
  company: 'company',
  heir: 'heir',
  uo: 'uo'
};

const IMPACT = {
  low: 'low',
  medium: 'medium',
  high: 'high'
};

const getFeatureFlags = (prefix: string) => {
  const flags = {
    'nl-NL': { 'uitvaart-ondernemers': true, 'case-studies': true },
    'nl-BE': {
      'uitvaart-ondernemers': false,
      'case-studies': true
    },
    'fr-BE': {
      'uitvaart-ondernemers': false,
      'case-studies': false
    }
  };
  return flags[prefix];
};

export { classNames, TYPECLIENT, IMPACT, getFeatureFlags };
