import React from 'react';
import { FormattedMessage } from 'react-intl';

import DocumentCheckIcon from '@heroicons/react/24/outline/DocumentCheckIcon';

import Button from '../Button';

export interface StatisticsProps {
  text: string;
  value: string;
}

export interface CaseStudyProps {
  title: string;
  description: JSX.Element | string;
  stat1?: StatisticsProps;
  img: JSX.Element | string;
  link: string;
}
function SliderCard({ title, description, stat1, img, link }: CaseStudyProps) {
  return (
    <div className="grid rounded-2xl py-12 px-8 lg:min-h-[540px] lg:grid-cols-2 lg:grid-rows-1 lg:items-center lg:px-20 lg:py-0">
      <div className="space-y-8">
        <div className="space-y-4">
          <span className="text-base text-blue lg:text-xl">
            <FormattedMessage id="caseStudies" />
          </span>
          <div className="overflow-hidden text-left font-header text-3xl text-blue lg:text-4xl lg:leading-snug">
            {title}
          </div>
          <p className="text-left text-base text-plum">{description}</p>
        </div>
        <div className="flex flex-row justify-start space-x-6">
          {stat1 && (
            <div className="text-left">
              <span className="text-base font-medium text-darkblue">{stat1.value}</span>
              <p className="text-sm text-plum">{stat1.text}</p>
            </div>
          )}
        </div>
        <div className="flex justify-center pb-8 sm:justify-start lg:pb-0">
          <Button link={link} type="primary" color="blue" variant="link">
            <FormattedMessage id="readmore" />
          </Button>
        </div>
      </div>
      <div className="relative mx-auto mt-20 hidden max-w-lg lg:mt-0 lg:block lg:pl-24">
        <div className="absolute bottom-24 left-44 z-10 flex select-none items-center justify-center space-x-2 rounded-lg bg-white px-3 py-2 text-xs">
          <div className="h-8 w-8 rounded-full bg-blue-50 p-2">
            <DocumentCheckIcon className="text-blue" />
          </div>
          <div className="text-xs text-plum">{stat1 && stat1.value}</div>
        </div>
        {img}
      </div>
    </div>
  );
}

export default SliderCard;
