import React from 'react';

// add a translated function for a french ContactSEO component
// and add it to the export default
function ContactSEOFRBE() {
  return (
    <meta
      name="description"
      content="
      Si vous avez des questions sur nos services, vous pouvez contacter gratuitement notre bureau des proches. Celui-ci est disponible du lundi au vendredi entre 08h30 et 21h00, le samedi entre 09h00 et 13h00."
    />
  );
}
function ContactSEO() {
  return (
    <meta
      name="description"
      content="
  Als je vragen hebt over onze dienstverlening kunt u gratis contact opnemen met onze nabestaandendesk. Deze is bereikbaar van maandag t/m vrijdag tussen 08:30 en 21:00, op zaterdag tussen 09:00 en 13:00."
    />
  );
}

export { ContactSEO, ContactSEOFRBE };
