import { HeadProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import ArrowTopRightOnSquareIcon from '@heroicons/react/24/outline/ArrowTopRightOnSquareIcon';
import ComputerDesktopIcon from '@heroicons/react/24/outline/ComputerDesktopIcon';

import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { ReactComponent as DashedLine1 } from '../../images/svg/dashed-line-1.svg';
import { ReactComponent as DashedLine2 } from '../../images/svg/dashed-line-2.svg';
import { ReactComponent as DashedLine3 } from '../../images/svg/dashed-line-3.svg';
import { ReactComponent as DashedLine4 } from '../../images/svg/dashed-line-4.svg';
import HeadParent from '../../utils/header';

function TMobile({ pageContext }) {
  return (
    <Layout pageContext={pageContext} navColor="bg-darkwhite" navTextColor="dark">
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-20">
        <div className="js-show-on-scroll">
          <div className="max-w-3xl space-y-4">
            <p className="text-lg font-medium text-blue">#Impactstories</p>
            <div className="font-header text-4xl leading-snug text-blue lg:text-5xl">
              Hoe Closure Odido volledig ontzorgt in het overlijdensproces.
            </div>
          </div>
        </div>
      </Section>
      <div className="flex justify-center bg-darkwhite">
        <DashedLine1 />
      </div>
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-12" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll">
          <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-3 lg:gap-16">
            <div className="col-span-1">
              <div className="flex items-center justify-center">
                <StaticImage
                  src="../../images/case-studies/t-mobile/bas-jansen.png"
                  alt="bas jansen Odido closure samenwerking"
                  className="w-full max-w-lg"
                  placeholder="none"
                />
              </div>
            </div>
            <div className="col-span-2 flex items-center">
              <div className="space-y-4">
                <div className=" font-header text-4xl leading-snug text-blue">
                  De missie van Odido.
                </div>
                <div className="flex flex-row space-x-4">
                  <div className="rounded-full bg-darkwhite-100 px-3 py-1 text-sm text-plum">
                    4.4+ miljoen klanten
                  </div>
                  <div className="rounded-full bg-darkwhite-100 px-3 py-1 text-sm text-plum">
                    Een van de grootste telecomproviders in Nederland
                  </div>
                </div>
                <p className="text-lg text-plum">
                  Als één van de grootste telecomproviders van Nederland, vervult Odido een
                  belangrijke rol in onze samenleving. Hierdoor voelt Odido zich verantwoordelijk
                  voor de mensen en de wereld om hen heen. Een van de initiatieven om mensen zo goed
                  mogelijk te helpen, is de Odido Nabestaandendesk. Deze nabestaandendesk is een
                  aantal jaar geleden tot stand gekomen om nabestaanden zo goed en zo snel mogelijk
                  te ontzorgen. Odido wilt nabestaanden met zo’n gevoelig onderwerp namelijk niet
                  onnodig laten wachten of ook maar iets verkeerd doen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <div className="flex justify-center bg-darkwhite">
        <DashedLine2 />
      </div>
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll mx-auto max-w-7xl">
          <div className="mx-auto mb-12 max-w-3xl space-y-4 text-center">
            <div className="font-header text-4xl text-blue">Achtergrond</div>
            <p className="text-left text-lg text-plum lg:text-center">
              Één van de mensen binnen Odido die hier een belangrijke rol in speelt, is Bas Jansen.
              Bas werkt al bijna 14 jaar bij Odido en is vanuit zijn functie Lead Contact Center Den
              Haag verantwoordelijk voor de aansturing van de operationele teams die ondersteuning
              bieden aan klanten van Odido. Bas gelooft sterk in de kracht van connectiviteit en
              doet er alles aan om mensen met elkaar te verbinden. Vanuit het Contact Center in Den
              Haag worden de vragen van klanten zo goed mogelijk beantwoord door verschillende
              focusgroepen, waaronder de nabestaandendesk. Er is heel bewust gekozen voor een team
              van professionals, zodat de nabestaanden zo goed mogelijk kunnen worden bijgestaan bij
              hun vragen.
            </p>
            <p className="text-left text-lg text-plum lg:text-center">
              Ondanks de professionals binnen de nabestaandendesk van Odido, is er alsnog gekozen
              voor een samenwerking met Closure. Het voornaamste doel van Odido om met Closure is
              gaan samenwerken, is om de klant nóg verder te ontzorgen. Wanneer het erop aankomt,
              vindt Odido het cruciaal dat je er optimaal bent voor klanten én dus ook voor de
              nabestaanden van klanten. Of het nu gaat om een omzetting naar prepaid, een
              contractovername of het opzeggen van een account: het is als provider altijd
              belangrijk om er voor je klanten te zijn.
            </p>
          </div>

          {/* <div className="grid grid-cols-1 gap-4 gap-y-8 md:gap-8 lg:grid-cols-3 lg:gap-y-0">
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Vind ssd</div>
              <p className="text-base text-plum">
                Vind een partner op onze partner pagina waarvan jij het abonnement wilt opzeggen of
                overzetten.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Vul het webformulier in</div>
              <p className="text-base text-plum">
                Vul eenmalig de vereiste informatie in om het abonnement op te zeggen of over te
                zetten.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Selecteer overige abonnementen</div>
              <p className="text-base text-plum">
                Aan de hand van de ingevulde gegevens kunnen we gelijk meerdere organisaties
                informeren.
              </p>
            </div>
          </div> */}
        </div>
      </Section>
      <div className="flex justify-center bg-darkwhite">
        <DashedLine3 />
      </div>
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll grid grid-cols-1 gap-16 lg:grid-cols-2">
          <div className="mb-12 max-w-3xl space-y-4">
            <div className="font-header text-4xl text-blue">De samenwerking</div>
            <p className="text-lg text-plum">
              Sinds de samenwerking met Closure is de kwaliteit van de nabestaandendesk van Odido
              toegenomen: de documenten zijn in één keer compleet en de inhoud is goed leesbaar.
              Hierdoor is het voor Odido niet nodig om meerdere contactmomenten aan te gaan met de
              nabestaanden, maar hebben ze aan één contactmoment genoeg om alles te kunnen regelen.
              De nabestaanden van Odido geven de klantvriendelijkheid van Odido sinds de
              samenwerking met Closure een 9. Deze tevredenheid bij nabestaanden over de service van
              Odido was precies de reden dat Odido deze samenwerking is aangegaan. Zowel Closure als
              Odido kijken dan ook uit naar wat de toekomst nog meer zal brengen.
            </p>
          </div>
          <div className="flex items-center justify-center">
            <div className="space-y-4">
              <div className="font-header text-3xl italic leading-snug text-darkblue lg:text-4xl">
                &quot;We zien dat we betere kwaliteit krijgen aangeleverd nu de melding via Closure
                loopt […] We hoeven niet terug naar de nabestaande om te vragen of ze nóg een keer
                die ID-kaart willen sturen, of betere scan willen maken van de
                overlijdensakte.&quot;
              </div>
              <div className="space-y-2">
                <div className="text-xl font-medium text-blue">Bas Jansen</div>
                <div className="text-lg text-plum">Manager Operations bij Odido</div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <div className="flex justify-center bg-darkwhite">
        <DashedLine4 />
      </div>
      {/* <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll">
          <div className="mx-auto mb-12 max-w-xl text-center font-header text-5xl text-blue">
            De impact
          </div>
          <div className="grid grid-cols-1 gap-4 gap-y-8 md:gap-8 lg:grid-cols-3 lg:gap-y-0">
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Vind ssd</div>
              <p className="text-base text-plum">
                Vind een partner op onze partner pagina waarvan jij het abonnement wilt opzeggen of
                overzetten.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Vul het webformulier in</div>
              <p className="text-base text-plum">
                Vul eenmalig de vereiste informatie in om het abonnement op te zeggen of over te
                zetten.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Selecteer overige abonnementen</div>
              <p className="text-base text-plum">
                Aan de hand van de ingevulde gegevens kunnen we gelijk meerdere organisaties
                informeren.
              </p>
            </div>
          </div>
        </div>
      </Section> */}
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll grid grid-cols-1 gap-16 lg:grid-cols-2">
          <div className="mb-12 max-w-3xl space-y-4">
            <div className="font-header text-4xl text-blue">Resources</div>
            <ul className="space-y-4">
              <li className="flex items-center justify-start space-x-4">
                <ComputerDesktopIcon className="h-6 w-6 text-blue" />
                <a href="https://odido.nabestaanden.support/" className="text-blue">
                  Bekijk hier de overlijdenspagina van Odido
                </a>
                <ArrowTopRightOnSquareIcon className="h-5 w-5 text-blue" />
              </li>
              <li className="flex items-center justify-start space-x-4">
                <ComputerDesktopIcon className="h-6 w-6 text-blue" />
                <a
                  href="https://www.linkedin.com/pulse/je-wilt-met-zon-gevoelig-onderwerp-mensen-niet-onnodig-carlijn-soer/"
                  className="text-blue"
                >
                  Bekijk hier het volledige interview met Bas van Odido
                </a>

                <ArrowTopRightOnSquareIcon className="h-5 w-5 text-blue" />
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-center">
            <div className="space-y-4">
              <div className="font-header text-3xl italic leading-snug text-darkblue lg:text-4xl">
                &quot;Het grootste gedeelte van de klanten kiest ervoor om de overlijdensmelding via
                Closure te doen.&quot;
              </div>
              <div className="space-y-2">
                <div className="text-xl font-medium text-blue">Bas Jansen</div>
                <div className="text-lg text-plum">Manager Operations bij Odido</div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export default TMobile;

export function Head(props: HeadProps) {
  const { location } = props;
  return (
    <HeadParent pageTitle="Odido Case Study" location={location}>
      <meta name="description" content="Description Odido case study" />
    </HeadParent>
  );
}
