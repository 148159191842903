import React from 'react';

function NabestaandenSEO() {
  return (
    <meta
      name="description"
      content="Closure biedt een gratis dienstverlening aan nabestaanden om snel en eenvoudig alle abonnementen van de overledene stop te zetten of over te zetten."
    />
  );
}

function NabestaandenSEOFEBR() {
  return (
    <meta
      name="description"
      content="Closure offre un service gratuit aux proches pour arrêter ou transférer rapidement et facilement tous les abonnements du défunt."
    />
  );
}

export { NabestaandenSEO, NabestaandenSEOFEBR };
