import { HeadProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Listbox } from '@headlessui/react';
import BellAlertIcon from '@heroicons/react/24/outline/BellAlertIcon';
import CheckIcon from '@heroicons/react/24/outline/CheckIcon';
import ChevronUpDownIcon from '@heroicons/react/24/outline/ChevronUpDownIcon';
import DocumentCheckIcon from '@heroicons/react/24/outline/DocumentCheckIcon';

import Button from '../../components/Button';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import FAQ from '../../components/FAQ';
import FooterCTA from '../../components/FooterCTA';
import Layout from '../../components/Layout';
import ModalDemo from '../../components/ModalDemo';
import Partners from '../../components/Partners';
import Section from '../../components/Section';
import SliderCard from '../../components/SliderCard';
import StepCard from '../../components/StepCard';
import TestimonialCard from '../../components/Testimonials';
import Title from '../../components/Typography/Title';
import {
  caseStudies,
  caseStudiesFRBE,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  faqs,
  industries,
  integrationTypes,
  integrationTypesFRBE,
  testimonials,
  testimonialsFRBE,
  USP,
  USPFRBE
} from '../../content/data/organisaties';
import { OrganizationSEO, OrganizationSEOFRBE } from '../../content/seo/organisaties';
import HeadParent from '../../utils/header';
import { classNames, IMPACT } from '../../utils/helpers';

const tabsStart = [
  {
    id: 1,
    name: <FormattedMessage id="uspTypeIntegrationLow" />,
    title: <FormattedMessage id="uspTypeIntegrationLowTitle" />,
    body: <FormattedMessage id="uspTypeIntegrationLowBody" />,
    type: IMPACT.low
  },
  {
    id: 2,
    name: <FormattedMessage id="uspTypeIntegrationMedium" />,
    title: <FormattedMessage id="uspTypeIntegrationMediumTitle" />,
    body: <FormattedMessage id="uspTypeIntegrationMediumBody" />,
    type: IMPACT.medium
  },
  {
    id: 3,
    name: <FormattedMessage id="uspTypeIntegrationHigh" />,
    title: <FormattedMessage id="uspTypeIntegrationHighTitle" />,
    body: <FormattedMessage id="uspTypeIntegrationHighBody" />,
    type: IMPACT.high
  }
];

function Organizations({ pageContext }) {
  const [domLoaded, setDomLoaded] = useState(false);
  const [selected, setSelected] = useState(industries[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [tabs] = useState(tabsStart);
  const [selectedTab, setSelectedTab] = useState(tabsStart[0]);
  const howItWorksSection = useRef<HTMLDivElement>(null);
  const { locale } = pageContext;
  const modalOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <Layout
      navColor="bg-darkwhite"
      cta={{ modal: modalOpen, text: <FormattedMessage id="companyHeaderCta1" /> }}
      navTextColor="dark"
      pageContext={pageContext}
    >
      <Section paddingBottom="" paddingTop="lg:pt-12" bgColor="bg-darkwhite">
        <div className="grid rounded-2xl pb-14 pt-8 text-left lg:min-h-[540px] lg:grid-cols-2 lg:grid-rows-1 lg:items-center lg:px-0 lg:py-0 ">
          <div className="space-y-8">
            <Title color="dark">
              <FormattedMessage id="companyHeaderTitle" />
            </Title>
            <p className="text-lg text-blue lg:text-lg">
              <FormattedMessage id="companyHeaderSubTitle" />
            </p>
            <div className="space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="block sm:inline">
                <Button type="primary" variant="modal" color="blue" modal={() => setIsOpen(true)}>
                  <FormattedMessage id="companyHeaderCta1" />
                </Button>
              </div>
              <div className="block sm:inline">
                <Button
                  variant="modal"
                  type="primary"
                  color="white"
                  modal={() => {
                    if (howItWorksSection.current !== null) {
                      howItWorksSection.current.scrollIntoView();
                    }
                  }}
                >
                  <FormattedMessage id="companyHeaderCta2" />
                </Button>
              </div>
            </div>
          </div>
          <div className="relative mx-auto flex max-w-md select-none items-center justify-center pt-12 lg:pt-0">
            <div className="absolute bottom-36 left-8 z-10 rounded-xl bg-white py-2 px-3">
              <div className="absolute -top-2 -right-2 flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                1
              </div>
              <div className="flex items-center justify-center space-x-2">
                <div className="h-8 w-8 rounded-full bg-blue-50 p-1.5">
                  <BellAlertIcon className="text-blue" />
                </div>
                <div className="space-y-2">
                  <div className="text-xs font-medium text-plum">
                    <FormattedMessage id="deathNotification" />
                  </div>
                </div>
              </div>
            </div>
            <div className=" absolute right-8 top-24 z-10">
              <div className="absolute -top-2 -right-3 flex h-6 w-6 items-center justify-center rounded-full bg-green-500 p-1 text-xs text-white">
                <CheckIcon />
              </div>

              <div className="rounded-t-xl bg-darkblue py-1 px-3 text-xs font-medium text-white">
                <FormattedMessage id="verifiedByClosure" />
              </div>
              <div className="rounded-b-xl bg-white py-2 px-3">
                <div className="flex items-center justify-center space-x-2">
                  <div className="h-8 w-8 rounded-full bg-blue-50 p-1.5">
                    <DocumentCheckIcon className="text-blue" />
                  </div>
                  <div className="space-y-2">
                    <div className="text-xs font-medium text-plum">
                      <FormattedMessage id="deathNotificationVerifiedByClosure" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StaticImage
              src="../../images/company-header-background.png"
              placeholder="none"
              className="w-full"
              alt="zen"
            />
          </div>
        </div>
      </Section>
      <Section paddingBottom="pb-12 lg:pb-28" paddingTop="pt-0" bgColor="bg-darkwhite">
        <Partners variant="corporate" color="blue" />
      </Section>
      <div ref={howItWorksSection}>
        <Section bgColor="bg-blue" paddingTop="pt-16 lg:pt-28" paddingBottom="pb-16 lg:pb-28">
          <div className="">
            <div className="mx-auto max-w-3xl text-left lg:text-center">
              <Title color="light">
                <FormattedMessage id="uspTitle" />
              </Title>
            </div>
            <div className="mx-auto mt-8 mb-12 flex max-w-lg flex-row items-center justify-start space-x-4 md:space-x-4 lg:mb-24 lg:justify-center">
              <span className="text-base text-offwhite lg:text-lg">
                <FormattedMessage id="uspChooseIndustry" />
              </span>
              <div className="w-32">
                <Listbox value={selected} onChange={setSelected}>
                  {() => (
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-darkwhite py-2 pl-3 pr-10 text-left text-offwhite sm:text-sm">
                        <span className="block truncate">{selected.text}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-offwhite" aria-hidden="true" />
                        </span>
                      </Listbox.Button>
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-darkwhite py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {industries.map(industry => (
                          <Listbox.Option
                            key={industry.id}
                            className={({ active }) =>
                              classNames(
                                active ? 'text-darkblue' : 'text-darkblue',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={industry}
                          >
                            {({ active }) => (
                              <span
                                className={classNames(
                                  active ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {industry.text}
                              </span>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  )}
                </Listbox>
              </div>
            </div>

            <div className="mx-auto grid max-w-5xl grid-cols-1 md:grid-cols-2">
              <div className="relative rounded-xl bg-offwhite px-8 py-14 md:mx-10 md:px-16 md:py-20">
                <div className="absolute top-6 right-6 rounded-full bg-darkwhite px-3 py-1.5 text-xs font-medium text-blue">
                  {selected.type}
                </div>
                <div className="border-b border-bordergray pb-5 font-header text-4xl font-medium text-blue">
                  <FormattedMessage id="uspCompanyTitle" />
                </div>
                {locale === 'fr-BE' &&
                  USPFRBE[selected.type as keyof typeof USP].company.map((feature, index) => (
                    <div
                      key={feature.id}
                      className={classNames(
                        USP[selected.type as keyof typeof USP].company.length !== index + 1
                          ? 'border-b pb-4'
                          : '',
                        'border-bordergray pt-4'
                      )}
                    >
                      <div className="mb-1 text-lg font-medium text-blue">{feature.title}</div>
                      <p className="text-sm text-plum">{feature.description}</p>
                    </div>
                  ))}
                {locale !== 'fr-BE' &&
                  USP[selected.type as keyof typeof USP].company.map((feature, index) => (
                    <div
                      key={feature.id}
                      className={classNames(
                        USP[selected.type as keyof typeof USP].company.length !== index + 1
                          ? 'border-b pb-4'
                          : '',
                        'border-bordergray pt-4'
                      )}
                    >
                      <div className="mb-1 text-lg font-medium text-blue">{feature.title}</div>
                      <p className="text-sm text-plum">{feature.description}</p>
                    </div>
                  ))}
              </div>
              <div className="relative mt-8 rounded-xl bg-offwhite px-8 py-14 text-darkblue md:mx-10 md:mt-0 md:px-16 md:py-20">
                <div className="absolute top-6 right-6 rounded-full bg-darkwhite px-3 py-1.5 text-xs font-medium text-blue">
                  {selected.type}
                </div>
                <div className="border-b border-bordergray pb-5 pr-4 font-header text-4xl font-medium text-blue">
                  <FormattedMessage id="uspHeirTitle" />
                </div>
                {locale === 'fr-BE' &&
                  USPFRBE[selected.type as keyof typeof USP].heirs.map((feature, index) => (
                    <div
                      key={feature.id}
                      className={classNames(
                        USP[selected.type as keyof typeof USP].heirs.length !== index + 1
                          ? 'border-b pb-4'
                          : '',
                        'border-bordergray pt-4'
                      )}
                    >
                      <div className="mb-1 text-lg font-medium text-blue">{feature.title}</div>
                      <p className="text-sm text-plum">{feature.description}</p>
                    </div>
                  ))}
                {locale !== 'fr-BE' &&
                  USP[selected.type as keyof typeof USP].heirs.map((feature, index) => (
                    <div
                      key={feature.id}
                      className={classNames(
                        USP[selected.type as keyof typeof USP].heirs.length !== index + 1
                          ? 'border-b pb-4'
                          : '',
                        'border-bordergray pt-4'
                      )}
                    >
                      <div className="mb-1 text-lg font-medium text-blue">{feature.title}</div>
                      <p className="text-sm text-plum">{feature.description}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Section>
      </div>
      <div>
        <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
          <div className="mx-auto mb-8 max-w-3xl space-y-4 text-left lg:mb-16 lg:text-center">
            <Title color="dark">
              <FormattedMessage id="uspHowItWorksTitle" />
            </Title>
            <p className="text-base text-plum lg:text-lg">
              <FormattedMessage id="uspHowItWorksBody" />
            </p>
          </div>
          <div className="mx-auto grid max-w-4xl grid-cols-1 gap-4 gap-y-8 md:gap-8 lg:grid-cols-2 lg:gap-y-0">
            <StepCard
              title={<FormattedMessage id="uspHowItWorksStep1Title" />}
              description={<FormattedMessage id="uspHowItWorksStep1Body" />}
              img={
                locale === 'fr-BE' ? (
                  <StaticImage
                    src="../../images/company-how-it-works-step-1-2-frbe.png"
                    alt="feature 1"
                    placeholder="none"
                    className="w-max-md lg:w-full"
                  />
                ) : (
                  <StaticImage
                    src="../../images/company-how-it-works-step-1-2.png"
                    alt="feature 1"
                    placeholder="none"
                    className="w-max-md lg:w-full"
                  />
                )
              }
            />
            <StepCard
              title={<FormattedMessage id="uspHowItWorksStep2Title" />}
              description={<FormattedMessage id="uspHowItWorksStep2Body" />}
              img={
                locale === 'fr-BE' ? (
                  <StaticImage
                    src="../../images/company-how-it-works-step-2-2-frbe.png"
                    alt="feature 1"
                    placeholder="none"
                    className="w-max-md lg:w-full"
                  />
                ) : (
                  <StaticImage
                    src="../../images/company-how-it-works-step-2-2.png"
                    alt="feature 1"
                    placeholder="none"
                    className="w-max-md lg:w-full"
                  />
                )
              }
            />
          </div>
        </Section>
      </div>
      <Section
        bgColor="bg-split-darkwhite-blue"
        paddingTop="pt-0 lg:pt-12"
        paddingBottom="pb-0 lg:pb-12"
      >
        {domLoaded && (
          <Swiper
            modules={[Pagination]}
            pagination
            loop
            noSwipingSelector="a"
            className="rounded-xl bg-offwhite"
            id="caseStudySlider"
          >
            {locale === 'fr-BE' &&
              caseStudiesFRBE.map(caseStudy => (
                <SwiperSlide key={caseStudy.id}>
                  <SliderCard {...caseStudy} />
                </SwiperSlide>
              ))}
            {locale === 'nl-NL' ||
              (locale === 'nl-BE' &&
                caseStudies.map(caseStudy => (
                  <SwiperSlide key={caseStudy.id}>
                    <SliderCard {...caseStudy} />
                  </SwiperSlide>
                )))}
          </Swiper>
        )}
      </Section>
      {/* <FAQ questions={faqs} /> */}
      <Section paddingTop="pt-16 lg:pt-40" paddingBottom="pb-16 lg:pb-40" bgColor="bg-blue">
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-16">
          <div className="col-span-1 mb-12 flex items-center justify-center text-center lg:mb-0 lg:justify-start lg:text-left">
            <div className="space-y-4">
              <Title color="light">
                <FormattedMessage id="uspTestimonialTitle" />
              </Title>
              <p className="text-base leading-relaxed text-darkwhite lg:text-xl">
                <FormattedMessage id="uspTestimonialSubTitle" />
              </p>
            </div>
          </div>
          <div className="col-span-2">
            {domLoaded && (
              <Swiper
                slidesPerView={1}
                spaceBetween={40}
                breakpoints={{
                  1024: {
                    slidesPerView: 2
                  }
                }}
              >
                {(locale === 'nl-NL' || locale === 'nl-BE') &&
                  testimonials.map(testimonial => (
                    <SwiperSlide>
                      <TestimonialCard key={testimonial.id} {...testimonial} />
                    </SwiperSlide>
                  ))}
                {locale === 'fr-BE' &&
                  testimonialsFRBE.map(testimonial => (
                    <SwiperSlide>
                      <TestimonialCard key={testimonial.id} {...testimonial} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </div>
        </div>
      </Section>
      <Section bgColor="bg-darkwhite" paddingTop="pt-16 lg:pt-28" paddingBottom="pb-16 lg:pb-28">
        <div className="mb-8 text-center lg:mb-16">
          <Title color="dark">
            <FormattedMessage id="uspTypeIntegrationTitle" />
          </Title>
        </div>
        <div>
          <div className="mx-auto max-w-2xl">
            <nav className="-mb-px flex justify-center" aria-label="Tabs">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  type="button"
                  className={classNames(
                    selectedTab.name === tab.name
                      ? 'border-blue text-blue'
                      : 'border-bordergray text-plum hover:text-darkblue',
                    'w-full whitespace-nowrap border-b-2 p-2 text-center text-sm sm:p-6 lg:text-lg'
                  )}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
          <div className="mx-auto mt-12 max-w-4xl sm:mt-20">
            {(locale === 'nl-NL' || locale === 'nl-BE') && (
              <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-16">
                <div>{integrationTypes[selectedTab.id - 1].img}</div>
                <div className="flex flex-col justify-center space-y-4">
                  <div className="text-left font-header text-3xl text-blue">
                    {integrationTypes[selectedTab.id - 1].title}
                  </div>
                  <p className="text-left text-base text-plum">
                    {integrationTypes[selectedTab.id - 1].body}
                  </p>
                </div>
              </div>
            )}
            {locale === 'fr-BE' && (
              <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-16">
                <div>{integrationTypesFRBE[selectedTab.id - 1].img}</div>
                <div className="flex flex-col justify-center space-y-4">
                  <div className="text-left font-header text-3xl text-blue">
                    {integrationTypesFRBE[selectedTab.id - 1].title}
                  </div>
                  <p className="text-left text-base text-plum">
                    {integrationTypesFRBE[selectedTab.id - 1].body}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Section>
      <FooterCTA bgInsideColor="bg-offwhite" bgColor="bg-darkwhite" />
      <ModalDemo isOpen={isOpen} setIsOpen={setIsOpen} />
    </Layout>
  );
}

export default Organizations;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  let pageTitle = '';
  switch (locale) {
    case 'nl-NL':
      pageTitle = 'Organisatie';
      break;
    case 'fr-BE':
      pageTitle = 'Organisation';
      break;
    case 'nl-BE':
      pageTitle = 'Organisatie';
      break;
    default:
      pageTitle = 'Organisatie';
      break;
  }
  return (
    <HeadParent pageTitle={pageTitle} location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <OrganizationSEO />}
      {locale === 'fr-BE' && <OrganizationSEOFRBE />}
    </HeadParent>
  );
}
