const vacancies = [
  {
    id: 1,
    title: 'Customer Operations',
    description: 'Full-time (vanaf 32 uur) • Rotterdam, NL • Vlaams, Frans & Engels',
    url: 'https://magnet.me/nl-NL/vacature/512172/customer-operations'
  }
];

// eslint-disable-next-line import/prefer-default-export
export { vacancies };
