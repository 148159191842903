import { HeadProps } from 'gatsby';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FooterCTA from '../../components/FooterCTA';
import Layout from '../../components/Layout';
import MediaCard from '../../components/MediaCard';
import Partners from '../../components/Partners';
import Section from '../../components/Section';
import Title from '../../components/Typography/Title';
import { news } from '../../content/data/press';
import { PersMediaSEO, PersMediaSEOFRBE } from '../../content/seo/pers-en-media';
import HeadParent from '../../utils/header';

function PersMedia({ pageContext }) {
  return (
    <Layout pageContext={pageContext} navColor="bg-darkwhite" navTextColor="dark">
      <Section paddingBottom="pb-8 lg:pb-12" paddingTop="pt-8 sm:pt-12" bgColor="bg-darkwhite">
        <div className="mx-auto max-w-7xl">
          <div className="pb-12 text-center lg:pb-24">
            <Title color="dark">
              <FormattedMessage id="mediaHeaderTitle" />
            </Title>
          </div>
          <Partners variant="media" />
        </div>
      </Section>
      <Section paddingTop="lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="mx-auto max-w-3xl">
          {news.map(article => (
            <MediaCard key={article.id} {...article} />
          ))}
        </div>
      </Section>
      <FooterCTA bgColor="bg-darkwhite" bgInsideColor="bg-darkwhite" />
    </Layout>
  );
}

export default PersMedia;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  let pageTitle = '';
  switch (locale) {
    case 'nl-NL':
      pageTitle = 'Pers & Media';
      break;
    case 'fr-BE':
      pageTitle = 'Presse et médias';
      break;
    case 'nl-BE':
      pageTitle = 'Pers & Media';
      break;
    default:
      pageTitle = 'Pers & Media';
      break;
  }
  return (
    <HeadParent pageTitle={pageTitle} location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <PersMediaSEO />}
      {locale === 'fr-BE' && <PersMediaSEOFRBE />}
    </HeadParent>
  );
}
