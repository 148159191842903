import type { HeadProps } from 'gatsby';
import { Script } from 'gatsby';
import React from 'react';

export interface HeadParentProps {
  children: React.ReactNode;
  pageTitle: string;
  location?: HeadProps['location'];
}
export default function HeadParent({ children, pageTitle, location }: HeadParentProps) {
  return (
    <>
      <title id="title">{pageTitle} - Closure</title>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      {location && <link rel="canonical" href={`https://closure.nl${location.pathname}`} />}
      <meta name="robots" content="index, follow" />
      <meta property="og:url" content="https://closure.nl/" />
      <meta property="og:locale" content="nl_NL" />
      <meta property="og:site_name" content="Closure" />
      <meta name="og:type" content="website" />
      <meta name="theme-color" content="#007F7F" />
      <meta charSet="utf-8" />
      {process.env.GATSBY_LEADINFO_KEY && (
        <Script>
          {`
        (function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
        l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
        l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
        }(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo",'${process.env.GATSBY_LEADINFO_KEY}'));
        `}
        </Script>
      )}
      {children}
    </>
  );
}
