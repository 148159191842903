import '../../styles/styles.css';

import { graphql, Script, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IntlProvider, useIntl } from 'react-intl';

import { MESSAGES } from '../../i18n/locales';
import Footer from './Footer';
import Nav, { CTAProps } from './Nav';
import Banner from '../Banner';

interface LayoutProps {
  children: React.ReactNode;
  navColor?: string;
  navTextColor?: 'dark' | 'white';
  cta?: CTAProps;
  pageContext: {
    locale: string;
    prefix: string;
  };
}

function Layout({
  children,
  navColor = 'bg-offwhite',
  navTextColor = 'dark',
  cta,
  pageContext
}: LayoutProps) {
  const [banner, setBanner] = useState(null);
  const intl = useIntl();
  useEffect(() => {
    axios.get(`${process.env.GATSBY_API_BASE_URL}/homepage/announcement`).then(res => {
      if (res.status === 200 && res.data) {
        setBanner(res.data);
      }
    });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const queryData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          default_locale
        }
      }
    }
  `);
  const defaultLocale = queryData.site.siteMetadata.default_locale;
  return (
    <IntlProvider
      messages={MESSAGES[pageContext.locale]}
      locale={pageContext.locale}
      defaultLocale={defaultLocale}
    >
      {banner && <Banner color="bg-offwhite">{banner}</Banner>}
      <div className="min-h-screen bg-offwhite h-full">
        <Nav navColor={navColor} navTextColor={navTextColor} cta={cta} pageContext={pageContext} />
        {children}
        <Footer pageContext={pageContext} />
      </div>
      <Script id="trengo">
        {`window.Trengo = window.Trengo || {};
          window.Trengo.key = '${process.env.GATSBY_TRENGO_KEY}';
          (function(d, script, t) {
              script = d.createElement('script');
              script.type = 'text/javascript';
              script.async = true;
              script.src = 'https://static.widget.trengo.eu/embed.js';
              d.getElementsByTagName('head')[0].appendChild(script);
              }(document));`}
      </Script>
    </IntlProvider>
  );
}

export default Layout;
