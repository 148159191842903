import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

export interface MediaCardProps {
  id: number;
  outlet: string;
  date: string;
  title: string;
  description: string;
  link: string;
}

export default function MediaCard({ id, outlet, date, title, description, link }: MediaCardProps) {
  return (
    <div key={id} className="flex flex-col gap-8 border-b border-gray-200 py-14 lg:flex-row">
      <div className="space-y-3">
        <div className="flex flex-row items-center text-base">
          <span className="font-medium text-primary">{outlet}</span>
          <p className="ml-2 text-plum">• {date}</p>
        </div>
        <div className="font-header text-xl text-blue">{title}</div>
        <p className="text-lg text-plum">{description}</p>
        <div className="mt-3">
          <Button variant="external" type="primary" link={link} color="blue">
            <FormattedMessage id="mediaArticleButton" />
          </Button>
        </div>
      </div>
    </div>
  );
}
