import React from 'react';

function AlgemeneVoorwaardenSEO() {
  return (
    <>
      <meta property="og:title" content="Algemene voorwaarden" />
      <meta
        name="description"
        property="og:description"
        content="Algemene voorwaarden van Closure"
      />
    </>
  );
}

function AlgemeneVoorwaardenSEOFRBE() {
  return (
    <>
      <meta property="og:title" content="Conditions générales" />
      <meta
        name="description"
        property="og:description"
        content="Conditions générales de Closure"
      />
    </>
  );
}

export { AlgemeneVoorwaardenSEO, AlgemeneVoorwaardenSEOFRBE };
