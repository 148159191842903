import { HeadProps } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LocalizedLink } from 'gatsby-plugin-i18n-l10n';

import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Title from '../components/Typography/Title';
import { cards } from '../content/data/home';
import { HomeSEO, HomeSEOFRBE } from '../content/seo/home';
import HeadParent from '../utils/header';
import { classNames, getFeatureFlags, TYPECLIENT } from '../utils/helpers';

const buttonNavigation = [
  { id: 1, text: <FormattedMessage id="heir_singular" />, url: 'nabestaanden' },
  { id: 2, text: <FormattedMessage id="company_singular" />, url: 'organisaties' },
  { id: 3, text: <FormattedMessage id="uo_singular" />, url: 'uitvaart-ondernemers' }
];

export function Index({ pageContext }) {
  const featureFlags = getFeatureFlags(pageContext.locale);
  const [domLoaded, setDomLoaded] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <Layout pageContext={pageContext} navTextColor="white" navColor="bg-primary">
      <div className="lg:block">
        <div className="h-full min-h-screen bg-grid-layout">
          <Section paddingBottom="pb-12 lg:pb-40" paddingTop="pt-12 lg:pt-28" bgColor="">
            <div className="mx-auto mb-28 max-w-2xl">
              <div className=" text-left lg:text-center">
                <div className="mb-8">
                  <Title color="light">
                    <FormattedMessage id="homeHeaderTitle" />
                  </Title>
                </div>
                <div className="mb-12 text-lg text-offwhite">
                  <FormattedMessage id="homeHeaderSubTitle" />
                </div>
                <div
                  className={classNames(
                    'mx-auto grid grid-cols-1 gap-6',
                    featureFlags['uitvaart-ondernemers']
                      ? 'lg:max-w-2xl lg:grid-cols-3'
                      : 'lg:max-w-md lg:grid-cols-2'
                  )}
                >
                  {buttonNavigation.map(item => {
                    if (
                      !featureFlags['uitvaart-ondernemers'] &&
                      item.url === 'uitvaart-ondernemers'
                    ) {
                      return null;
                    }
                    return (
                      <LocalizedLink
                        to={intl.formatMessage({ id: `url.${item.url}` })}
                        key={item.id}
                      >
                        <div className="w-full rounded-full bg-blue py-6 text-base text-white transition-colors duration-300 hover:bg-secondary hover:text-white lg:py-3">
                          <div className="flex items-center justify-center space-x-2">
                            <div className="">{item.text}</div>
                            <div className="">
                              <ChevronRightIcon className="h-4 w-4" />
                            </div>
                          </div>
                        </div>
                      </LocalizedLink>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="mx-auto max-w-4xl" id="homePageSlider">
              {domLoaded && (
                <Swiper
                  modules={[Pagination, EffectCoverflow]}
                  effect="coverflow"
                  centeredSlides
                  touchStartPreventDefault={false}
                  slideToClickedSlide
                  noSwipingSelector="a"
                  initialSlide={2}
                  breakpoints={{
                    320: {
                      slidesPerView: 1
                    },
                    600: {
                      slidesPerView: 3
                    }
                  }}
                  slidesPerView={3}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 3,
                    slideShadows: false
                  }}
                  pagination={{
                    bulletClass: 'swiper-pagination-bullet',
                    bulletActiveClass: 'swiper-pagination-bullet-active'
                  }}
                >
                  {cards.map((item, index) => (
                    <SwiperSlide virtualIndex={index} key={item.id}>
                      {({ isActive, isPrev, isNext }) => (
                        <div className="rounded-xl bg-offwhite">
                          {!isActive && (
                            <div
                              className={classNames(
                                isPrev || isNext ? 'opacity-30' : 'opacity-60',
                                'absolute z-10 h-full w-full rounded-lg bg-primary transition-opacity'
                              )}
                            />
                          )}
                          <div
                            className={classNames(
                              'rounded-t-lg bg-offwhite p-6',
                              isActive ? 'rounded-b-none' : 'rounded-b-lg delay-300'
                            )}
                          >
                            <div className="mb-10">
                              <div className="absolute mb-2 rounded-lg bg-darkwhite px-2 py-1 text-xs font-medium text-blue">
                                {item.type === 'uo' &&
                                featureFlags['uitvaart-ondernemers'] === false ? (
                                  <div>
                                    <FormattedMessage id="company" />
                                  </div>
                                ) : (
                                  <div>{item.typePlaceholder}</div>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col items-center justify-center space-y-8">
                              <div className="">
                                <div
                                  className={classNames(
                                    'h-32 font-header text-xl text-blue',
                                    isActive ? 'blur-none' : 'blur-small',
                                    'transition delay-150 duration-300 ease-out'
                                  )}
                                >
                                  {item.text}
                                </div>
                              </div>
                              {item.image}
                            </div>
                          </div>
                          <LocalizedLink
                            to={
                              item.type === TYPECLIENT.heir
                                ? intl.formatMessage({ id: 'url.nabestaanden' })
                                : item.type === TYPECLIENT.company
                                ? intl.formatMessage({ id: 'url.organisaties' })
                                : featureFlags['uitvaart-ondernemers']
                                ? intl.formatMessage({ id: 'url.uitvaart-ondernemers' })
                                : intl.formatMessage({ id: 'url.organisaties' })
                            }
                            className={classNames(
                              'w-full cursor-pointer transition-opacity',
                              isActive ? 'opacity-1' : 'opacity-0'
                            )}
                          >
                            <div className="w-full rounded-b-lg bg-offwhite pt-6 pb-2">
                              <div className="text-center text-base text-blue">
                                <FormattedMessage id="moreInfo" />
                                <br />
                                <div className="font-medium">
                                  {item.type === 'uo' &&
                                  featureFlags['uitvaart-ondernemers'] === false ? (
                                    <div>
                                      <FormattedMessage id="company" />
                                    </div>
                                  ) : (
                                    <div>{item.typePlaceholder}</div>
                                  )}
                                </div>
                              </div>
                              <div className="mt-2 flex justify-center">
                                <ChevronDownIcon className="w-5 animate-bounce text-blue" />
                              </div>
                            </div>
                          </LocalizedLink>
                        </div>
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </Section>
        </div>
      </div>
    </Layout>
  );
}

export default Index;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  let pageTitle = '';
  switch (locale) {
    case 'nl-NL':
      pageTitle = 'Home';
      break;
    case 'fr-BE':
      pageTitle = 'Home';
      break;
    case 'nl-BE':
      pageTitle = 'Home';
      break;
    default:
      pageTitle = 'Home';
      break;
  }
  return (
    <HeadParent pageTitle={pageTitle} location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <HomeSEO />}
      {locale === 'fr-BE' && <HomeSEOFRBE />}
    </HeadParent>
  );
}
