import { HeadProps } from 'gatsby';
import { LocalizedLink } from 'gatsby-plugin-i18n-l10n';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '../../components/Button';
import FAQ from '../../components/FAQ';
import Layout from '../../components/Layout';
import Partners from '../../components/Partners';
import Section from '../../components/Section';
import StepCard from '../../components/StepCard';
import Title from '../../components/Typography/Title';
import { faqs, faqsFRBE } from '../../content/data/nabestaanden';
import { NabestaandenSEO, NabestaandenSEOFEBR } from '../../content/seo/nabestaanden';
import HeadParent from '../../utils/header';

function Nabestaanden({ pageContext }) {
  const howItWorksSection = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { locale } = pageContext;
  return (
    <Layout
      pageContext={pageContext}
      navColor="bg-primary"
      navTextColor="white"
      cta={{ https: true, link: 'portalUrl', text: 'Login' }}
    >
      <Section paddingBottom="lg:pb-12" paddingTop="pt-0 sm:pt-12" bgColor="bg-primary">
        <div className="grid rounded-2xl pb-14 pt-8 text-left lg:min-h-[540px] lg:grid-cols-2 lg:grid-rows-1 lg:items-center lg:px-0 lg:py-0 ">
          <div className="space-y-8 ">
            <Title color="light">
              <FormattedMessage id="heirHeaderTitle" />
            </Title>
            <p className="text-lg text-offwhite">
              <FormattedMessage id="heirHeaderSubTitle" />
            </p>
            <div className="space-y-4 sm:space-x-4">
              <div className="block sm:inline">
                <Button variant="link" type="primary" color="blue" link="partners">
                  <FormattedMessage id="heirHeaderCta1" />
                </Button>
              </div>
              <div className="block sm:inline">
                <Button
                  variant="modal"
                  type="primary"
                  color="white"
                  modal={() => {
                    if (howItWorksSection.current !== null) {
                      howItWorksSection.current.scrollIntoView();
                    }
                  }}
                >
                  <FormattedMessage id="heirHeaderCta2" />
                </Button>
              </div>
            </div>
            <div className="">
              {locale === 'fr-BE' && (
                <a href="https://app.closure.fr/fr/portal/login">
                  <button
                    type="button"
                    className="cursor-pointer border-b-2 border-darkwhite pb-2 text-sm text-darkwhite"
                  >
                    <FormattedMessage id="alreadyAccount" />
                  </button>
                </a>
              )}
              {locale === 'nl-BE' && (
                <a href="https://closure.be/portal/login">
                  <button
                    type="button"
                    className="cursor-pointer border-b-2 border-darkwhite pb-2 text-sm text-darkwhite"
                  >
                    <FormattedMessage id="alreadyAccount" />
                  </button>
                </a>
              )}
              {locale === 'nl-NL' && (
                <a href="https://closure.nl/portal/login">
                  <button
                    type="button"
                    className="cursor-pointer border-b-2 border-darkwhite pb-2 text-sm text-darkwhite"
                  >
                    <FormattedMessage id="alreadyAccount" />
                  </button>
                </a>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end pt-12 lg:px-16 lg:pt-0">
            {(locale === 'nl-NL' || locale === 'nl-BE') && (
              <StaticImage
                src="../../images/heir-handle-subscriptions.png"
                placeholder="none"
                alt="Hero Heir"
                className="mx-auto w-full max-w-xl"
              />
            )}
            {locale === 'fr-BE' && (
              <StaticImage
                src="../../images/heir-handle-subscriptions-frbe.png"
                placeholder="none"
                alt="Hero Heir"
                className="mx-auto w-full max-w-xl"
              />
            )}
          </div>
        </div>
      </Section>
      <Section paddingBottom="pb-12 lg:pb-28" paddingTop="pt-0" bgColor="bg-primary">
        <Partners color="white" variant="corporate" />
      </Section>
      <div ref={howItWorksSection}>
        <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
          <div className="mx-auto mb-8 max-w-3xl space-y-4 text-left lg:mb-16  lg:text-center">
            <Title color="dark">
              <FormattedMessage id="heirHowItWorksTitle" />
            </Title>
            <p className="text-lg text-plum">
              <FormattedMessage id="heirHowItWorksSubTitle" />
            </p>
          </div>
          <div className="grid grid-cols-1 gap-4 gap-y-8 md:gap-8 lg:grid-cols-3 lg:gap-y-0">
            <StepCard
              title={<FormattedMessage id="heirHowItWorksStep1Title" />}
              description={
                <FormattedMessage
                  id="heirHowItWorksStep1Body"
                  values={{
                    link: (
                      <LocalizedLink to={intl.formatMessage({ id: 'url.partners' })}>
                        <span className="font-medium text-blue">
                          <FormattedMessage id="here" />
                        </span>
                      </LocalizedLink>
                    )
                  }}
                />
              }
              img={
                <StaticImage src="../../images/heir-how-it-works-step-1.png" alt="step 1 heir" />
              }
            />
            <StepCard
              title={<FormattedMessage id="heirHowItWorksStep2Title" />}
              description={<FormattedMessage id="heirHowItWorksStep2Body" />}
              img={
                <StaticImage
                  src="../../images/heir-how-it-works-step-2.png"
                  alt="step 2 heir"
                  placeholder="none"
                />
              }
            />
            <StepCard
              title={<FormattedMessage id="heirHowItWorksStep3Title" />}
              description={<FormattedMessage id="heirHowItWorksStep3Body" />}
              img={
                <StaticImage
                  src="../../images/heir-how-it-works-step-3.png"
                  alt="step 3 heir"
                  placeholder="none"
                />
              }
            />
          </div>
        </Section>
      </div>
      <Section bgColor="bg-split-darkwhite-blue">
        <div className="rounded-2xl bg-offwhite p-8 lg:p-10">
          <div className="mx-auto max-w-3xl lg:text-center">
            <div className="mb-8 font-header text-3xl text-blue lg:text-4xl">
              <FormattedMessage id="heirCtaBoxTitle" />
            </div>
            <p className="mb-8 text-base text-plum">
              <FormattedMessage id="heirCtaBoxBody" />
            </p>
            <Button variant="link" link="partners" type="primary" color="blue">
              <FormattedMessage id="heirCtaBoxButton" />
            </Button>
          </div>
        </div>
      </Section>
      {locale === 'fr-BE' && <FAQ questions={faqsFRBE} />}
      {(locale === 'nl-NL' || locale === 'nl-BE') && <FAQ questions={faqs} />}
    </Layout>
  );
}

export default Nabestaanden;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  let pageTitle = '';
  switch (locale) {
    case 'nl-NL':
      pageTitle = 'Nabestaanden';
      break;
    case 'fr-BE':
      pageTitle = 'Proches';
      break;
    case 'nl-BE':
      pageTitle = 'Nabestaanden';
      break;
    default:
      pageTitle = 'Nabestaanden';
      break;
  }
  return (
    <HeadParent pageTitle={pageTitle} location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <NabestaandenSEO />}
      {locale === 'fr-BE' && <NabestaandenSEOFEBR />}
    </HeadParent>
  );
}
