import { HeadProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import CheckIcon from '@heroicons/react/24/outline/CheckIcon';
import ClockIcon from '@heroicons/react/24/outline/ClockIcon';
import PhoneIcon from '@heroicons/react/24/outline/PhoneIcon';

import Button from '../../components/Button';
import FAQ from '../../components/FAQ';
import Layout from '../../components/Layout';
import Partners from '../../components/Partners';
import Section from '../../components/Section';
import Title from '../../components/Typography/Title';
import { benefits, faqs } from '../../content/data/uitvaartondernemers';
import UitvaartOndernemersSEO from '../../content/seo/uitvaart-ondernemers';
import HeadParent from '../../utils/header';

function Uitvaartondernemers({ pageContext }) {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <Layout
      pageContext={pageContext}
      navColor="bg-darkwhite"
      navTextColor="dark"
      cta={{ text: <FormattedMessage id="uoHeaderButton" />, link: 'https://nabestaandenloket.nl' }}
    >
      <Section paddingBottom="pb-12 lg:pb-12" paddingTop="lg:pt-12" bgColor="bg-darkwhite">
        <div className="mx-auto max-w-3xl pb-12 pt-8 text-left lg:py-14 lg:text-center">
          <div className="space-y-8">
            <Title color="dark">
              <FormattedMessage id="uoHeaderTitle" />
            </Title>
            <p className="text-lg text-blue lg:text-lg">
              <FormattedMessage id="uoHeaderSubTitle" />
            </p>
            <div className="space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="mx-auto flex flex-col justify-center lg:flex-row lg:items-center">
                <Button
                  type="primary"
                  color="blue"
                  variant="external"
                  link="https://nabestaandenloket.nl"
                >
                  <FormattedMessage id="uoHeaderButton" />
                </Button>
                <p className="ml-4 mt-4 whitespace-nowrap lg:mt-0 lg:whitespace-normal">
                  <FormattedMessage id="uoCTA2Text" />
                  <span className="font-medium">
                    <FormattedMessage id="uoPhoneNumber" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto hidden max-w-7xl lg:block">
          {domLoaded && (
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination
              loop
              slidesPerView={1}
              autoplay={{
                delay: 10000
              }}
              speed={2000}
              id="uoPageSlider"
            >
              <SwiperSlide>
                <StaticImage
                  src="../../images/uo-header-slide-1.png"
                  className="w-full"
                  placeholder="none"
                  alt="browser nbl"
                  quality={100}
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/uo-header-slide-2.png"
                  className="w-full"
                  placeholder="none"
                  alt="browser nbl"
                  quality={100}
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  src="../../images/uo-header-slide-3.png"
                  className="w-full"
                  placeholder="none"
                  alt="browser nbl"
                  quality={100}
                />
              </SwiperSlide>
            </Swiper>
          )}
        </div>
      </Section>
      <Section paddingBottom="pb-12 lg:pb-28" paddingTop="pt-0" bgColor="bg-darkwhite">
        <Partners variant="uitvaartondernemers" color="color" />
      </Section>
      {/* <Section bgColor="bg-blue" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="mx-auto mb-8 max-w-2xl space-y-4 text-center lg:mb-16">
          <Title color="light">
            <FormattedMessage id="uoServiceTitle" />
          </Title>
          <p className="text-base text-offwhite lg:text-lg">
            <FormattedMessage id="uoServiceBody" />
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 gap-y-8 md:gap-8 lg:grid-cols-3 lg:gap-y-0">
          <StepCard
            title={<FormattedMessage id="uoService1Title" />}
            description={<FormattedMessage id="uoService1Body" />}
            img={
              <StaticImage
                src="../../images/stop-subscriptions.png"
                alt="feature 1"
                placeholder="none"
                className="w-40 lg:w-full"
                quality={100}
              />
            }
          />
          <StepCard
            title={<FormattedMessage id="uoService2Title" />}
            description={<FormattedMessage id="uoService2Body" />}
            img={
              <StaticImage
                src="../../images/uo-customer-experience.png"
                alt="feature 2"
                placeholder="none"
                className="w-40 lg:w-full"
                quality={100}
              />
            }
          />
          <StepCard
            title={<FormattedMessage id="uoService3Title" />}
            description={<FormattedMessage id="uoService3Body" />}
            img={
              <StaticImage
                src="../../images/customer-service-operational-costs.png"
                alt="feature 3"
                placeholder="none"
                className="w-40 lg:w-full"
                quality={100}
              />
            }
          />
        </div>
      </Section> */}
      <Section paddingBottom="pb-12 lg:pb-28" paddingTop="pt-12 lg:pt-28" bgColor="bg-offwhite">
        <div className="space-y-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-16">
            <div className="mx-auto flex max-w-xs items-center justify-center lg:hidden">
              <StaticImage
                src="../../images/uo-feature-cancel-subscriptions.png"
                className="w-full"
                placeholder="none"
                alt="opzegdienst feature"
                quality={100}
              />
            </div>
            <div className="mx-auto hidden items-center justify-center lg:flex lg:max-w-sm">
              <StaticImage
                src="../../images/uo-feature-cancel-subscriptions.png"
                className="w-full"
                placeholder="none"
                alt="opzegdienst feature"
                quality={100}
              />
            </div>
            <div className="flex items-center">
              <div className="space-y-8">
                <Title color="dark">
                  <FormattedMessage id="uoFeature1Title" />
                </Title>
                <p className="text-base text-plum lg:text-lg">
                  <FormattedMessage id="uoFeature1Body" />
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div className="mx-auto flex max-w-xs items-center justify-center lg:hidden">
              <StaticImage
                src="../../images/uo-feature-tasks.png"
                className="w-full"
                placeholder="none"
                alt="administratieve zaken feature"
                quality={100}
              />
            </div>
            <div className="flex items-center">
              <div className="space-y-8">
                <Title color="dark">
                  <FormattedMessage id="uoFeature2Title" />
                </Title>
                <p className="text-base text-plum lg:text-lg">
                  <FormattedMessage id="uoFeature2Body" />
                </p>
              </div>
            </div>
            <div className="mx-auto hidden items-center justify-center lg:flex lg:max-w-xl">
              <StaticImage
                src="../../images/uo-feature-tasks.png"
                className="w-full"
                placeholder="none"
                alt="administratieve zaken feature"
                quality={100}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div className="mx-auto flex max-w-xs items-center justify-center lg:hidden">
              <StaticImage
                src="../../images/uo-feature-omp.png"
                className="w-full"
                placeholder="none"
                alt="afscheidspagina feature"
                quality={100}
              />
            </div>
            <div className="mx-auto hidden items-center justify-center lg:flex lg:max-w-sm">
              <StaticImage
                src="../../images/uo-feature-omp.png"
                className="w-full"
                placeholder="none"
                alt="afscheidspagina feature"
                quality={100}
              />
            </div>
            <div className="flex items-center">
              <div className="space-y-8">
                <Title color="dark">
                  <FormattedMessage id="uoFeature3Title" />
                </Title>
                <p className="text-base text-plum lg:text-lg">
                  <FormattedMessage id="uoFeature3Body" />
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-16">
            <div className="mx-auto flex items-center justify-center lg:hidden lg:max-w-lg">
              <StaticImage
                src="../../images/uo-heir-support.png"
                className="w-full"
                placeholder="none"
                alt="klantenservice medewerker"
                quality={100}
              />
            </div>
            <div className="flex items-center lg:text-left">
              <div className="space-y-8">
                <Title color="dark">
                  <FormattedMessage id="uoNBLDeskTitle" />
                </Title>
                <p className="text-lg text-plum">
                  <FormattedMessage id="uoNBLDeskBody" />
                </p>
                <div className="space-y-2">
                  <div className="flex items-center justify-start space-x-4 p-2">
                    <div className="h-6 w-6">
                      <ClockIcon className="w-6 text-blue" />
                    </div>
                    <div className="text-left text-lg text-plum">
                      <FormattedMessage id="uoNBlDeskBenefitFeature2" />
                    </div>
                  </div>
                  <div className="flex items-center justify-start space-x-4 p-2 ">
                    <PhoneIcon className="min-h-6 min-w-6 h-6 w-6 text-blue" />
                    <div className="text-lg text-plum">
                      <FormattedMessage id="uoNBlDeskBenefiFeature3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto hidden items-center justify-center lg:flex lg:max-w-lg">
              <StaticImage
                src="../../images/uo-heir-support.png"
                className="w-full"
                placeholder="none"
                alt="klantenservice medewerker"
                quality={100}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section paddingBottom="pb-12 lg:pb-28" paddingTop="pt-12 lg:pt-28" bgColor="bg-darkwhite">
        <div className="mx-auto text-center">
          <div className="mb-12 text-left lg:mb-28 lg:text-center">
            <Title color="dark">
              <FormattedMessage id="uoSecondaryBenefitsTitle" />
            </Title>
          </div>
          <div className="mx-auto max-w-7xl">
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-16">
              {benefits.map(benefit => (
                <div key={benefit.id} className="rounded-xl bg-darkwhite-600 p-6">
                  <div className="flex items-center space-x-4">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-darkwhite-800">
                      <CheckIcon className="h-6 w-6 text-blue" />
                    </div>
                    <div className="text-left text-base  text-blue">{benefit.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section>
      <FAQ questions={faqs} />
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28">
        <div className="rounded-2xl bg-darkwhite pt-12 pb-12 lg:px-10">
          <div className="mx-auto max-w-2xl lg:text-center">
            <div className="mb-8 font-header text-4xl text-blue lg:text-4xl">
              <FormattedMessage id="uoFooterCTATitle" />
            </div>
            <p className="mb-8 text-base text-blue">
              <FormattedMessage id="uoFooterCTABody" />
            </p>
            <Button type="primary" variant="link" link="https://nabestaandenloket.nl" color="blue">
              <FormattedMessage id="uoFooterCTAButton" />
            </Button>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export default Uitvaartondernemers;

export function Head(props: HeadProps) {
  const { location } = props;
  return (
    <HeadParent pageTitle="Uitvaartondernemers" location={location}>
      <UitvaartOndernemersSEO />
    </HeadParent>
  );
}
