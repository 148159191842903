import { HeadProps } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../components/Button';
import FooterCTA from '../../components/FooterCTA';
import Layout from '../../components/Layout';
import ModalDemo from '../../components/ModalDemo';
import Section from '../../components/Section';
import { ContactSEO, ContactSEOFRBE } from '../../content/seo/contact';
import HeadParent from '../../utils/header';

function Contact({ pageContext }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.Trengo = window.Trengo || {};
  }, []);
  return (
    <Layout pageContext={pageContext} navTextColor="dark">
      <Section paddingTop="pt-8 lg:pt-28" paddingBottom="pb-16 lg:pb-28">
        <div className="mx-auto max-w-xl">
          <div className="mb-8 text-center text-lg font-medium text-primary">
            <FormattedMessage id="contactCategory" />
          </div>
          <div className="text-center font-header text-4xl text-blue lg:text-5xl">
            <FormattedMessage id="contactHeaderTitle" />
          </div>
        </div>
      </Section>
      <Section paddingTop="pt-0" paddingBottom="pb-16 lg:pb-28">
        <div className="mx-auto grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div className="flex flex-col items-center justify-center space-y-4 rounded-xl bg-primary-500 p-10 text-center">
            <div className="font-header text-2xl text-offwhite">
              <FormattedMessage id="contactHeirTitle" />
            </div>
            <p className="text-base text-offwhite">
              <FormattedMessage id="contactHeirBody" />
            </p>
            <div className="flex items-center space-x-4">
              <Button
                type="primary"
                color="blue"
                link="/"
                variant="modal"
                modal={() => window.Trengo.Api.Widget.open('chat')}
              >
                <FormattedMessage id="contactHeirButton" />
              </Button>
              <div className="text-offwhite">
                <FormattedMessage id="contactHeirOptional" />
                <span className="font-medium">010 200 4522</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center space-y-4 rounded-xl bg-darkwhite p-10 text-center">
            <div className="font-header text-2xl text-darkblue">
              <FormattedMessage id="contactSalesTitle" />
            </div>
            <p className="text-base text-plum">
              <FormattedMessage id="contactSalesBody" />
            </p>
            <Button type="primary" color="blue" variant="modal" modal={() => setIsOpen(true)}>
              <FormattedMessage id="contactSalesButton" />
            </Button>
          </div>
          <div className="flex flex-col items-center justify-center space-y-4 rounded-xl bg-darkblue p-10 text-center">
            <div className="font-header text-2xl text-offwhite">
              <FormattedMessage id="contactMediaTitle" />
            </div>
            <p className="text-base text-offwhite">
              <FormattedMessage id="contactMediaBody" />
            </p>
            <Button type="primary" color="white" link="mailto:pers@closure.nl" variant="external">
              <FormattedMessage id="contactMediaButton" />
            </Button>
          </div>
        </div>
      </Section>
      <ModalDemo isOpen={isOpen} setIsOpen={setIsOpen} />
      <FooterCTA bgColor="bg-darkwhite" bgInsideColor="bg-darkwhite" />
    </Layout>
  );
}

export default Contact;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  return (
    <HeadParent pageTitle="Contact" location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <ContactSEO />}
      {locale === 'fr-BE' && <ContactSEOFRBE />}
    </HeadParent>
  );
}
