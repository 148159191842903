import { StaticImage } from 'gatsby-plugin-image';
import type { HeadProps } from 'gatsby';
import React from 'react';

import ArrowTopRightOnSquareIcon from '@heroicons/react/24/outline/ArrowTopRightOnSquareIcon';
import ComputerDesktopIcon from '@heroicons/react/24/outline/ComputerDesktopIcon';

import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { ReactComponent as DashedLine1 } from '../../images/svg/dashed-line-1.svg';
import { ReactComponent as DashedLine2 } from '../../images/svg/dashed-line-2.svg';
import { ReactComponent as DashedLine3 } from '../../images/svg/dashed-line-3.svg';
import { ReactComponent as DashedLine4 } from '../../images/svg/dashed-line-4.svg';
import HeadParent from '../../utils/header';

function OvChipkaart({pageContext}) {
  return (
    <Layout pageContext={pageContext} navColor="bg-darkwhite" navTextColor="dark">
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-20">
        <div className="js-show-on-scroll">
          <div className="max-w-3xl space-y-4">
            <p className="text-lg font-medium text-blue">#Impactstories</p>
            <div className="font-header text-4xl leading-snug text-blue lg:text-5xl">
              Hoe Closure OV-chipkaart volledig ontzorgt in het overlijdensproces.
            </div>
          </div>
        </div>
      </Section>
      <div className="flex justify-center bg-darkwhite">
        <DashedLine1 />
      </div>
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-12" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll">
          <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-3 lg:gap-16">
            <div className="col-span-1">
              <div className="flex items-center justify-center">
                <StaticImage
                  src="../../images/case-studies/ov-chipkaart/jokelien.png"
                  alt="josefien ov-chipkaart"
                  className="w-full max-w-lg"
                  placeholder="none"
                />
              </div>
            </div>
            <div className="col-span-2 flex items-center">
              <div className="space-y-4">
                <div className=" font-header text-4xl leading-snug text-blue">
                  OV-chipkaart in Nederland
                </div>
                <div className="flex flex-row space-x-4">
                  <div className="rounded-full bg-darkwhite-100 px-6 py-3 text-sm text-plum lg:px-3 lg:py-1">
                    13.5 miljoen + gebruikers
                  </div>
                  <div className="rounded-full bg-darkwhite-100 px-6 py-3  text-sm text-plum lg:px-3 lg:py-1">
                    2.5 miljard transacties / jaar
                  </div>
                </div>
                <p className="text-lg text-plum">
                  Vrijwel iedereen in Nederland beschikt tegenwoordig over een OV-chipkaart. Met de
                  OV-chipkaart kunnen mensen betalen in het Openbaar Vervoer (OV). Een groot aantal
                  mensen denkt nog steeds dat de OV-chipkaart van een vervoerder is, maar dat is
                  niet het geval. Het bedrijf Translink zit achter de OV-chipkaart. Translink maakt
                  de kaart, geeft de kaart uit, verzorgt de serviceverlening en verwerkt de ongeveer
                  2,5 miljard reis- en financiële transacties die jaarlijks met OV-chipkaarten
                  worden gedaan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <div className="flex justify-center bg-darkwhite">
        <DashedLine2 />
      </div>
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll mx-auto max-w-7xl">
          <div className="mx-auto mb-12 max-w-3xl space-y-4 text-center">
            <div className="font-header text-4xl text-blue">Maatschappelijke rol</div>
            <p className="pb-12 text-left text-lg text-plum lg:text-center">
              Aangezien er in Nederland ruim 15.3 miljoen actieve OV-chipkaarten in de omloop zijn,
              heeft Translink een groot netwerk aan gebruikers. Helaas betekent dit ook dat ze
              regelmatig te maken krijgen met gebruikers die overlijden en een account bij
              OV-chipkaart achterlaten. Vanwege het verantwoordelijkheidsgevoel en de
              maatschappelijke rol van Translink, hebben ze gekozen voor een samenwerking met
              Closure. Met deze samenwerking heeft Translink als voornaamste doel om de nabestaanden
              binnen hun organisatie zo goed mogelijk van dienst te zijn.
            </p>
            <div className="mt-8 font-header text-4xl text-blue">
              Voor de samenwerking met Closure
            </div>
            <p className="text-left text-lg text-plum lg:text-center">
              Voorheen kon een OV-chipkaartaccount van een overleden persoon alleen via een
              formulier worden beëindigd. Een nabestaande moest dan een formulier printen, het
              formulier invullen, de OV-chipkaart en een kopie van de akte van overlijden bijvoegen,
              alle documenten in een envelop doen en versturen. Oftewel, een omslachtig en langdurig
              proces dat niet uiterst klantvriendelijk was, terwijl Translink klantvriendelijk juist
              hoog heeft zitten. Ze voelden zich dan ook gelijk verbonden met Closure. Dit was voor
              Translink dé manier om de administratieve rompslomp voor nabestaanden weg te nemen en
              de klantvriendelijkheid te verhogen.
            </p>
          </div>

          {/* <div className="grid grid-cols-1 gap-4 gap-y-8 md:gap-8 lg:grid-cols-3 lg:gap-y-0">
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Vind ssd</div>
              <p className="text-base text-plum">
                Vind een partner op onze partner pagina waarvan jij het abonnement wilt opzeggen of
                overzetten.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Vul het webformulier in</div>
              <p className="text-base text-plum">
                Vul eenmalig de vereiste informatie in om het abonnement op te zeggen of over te
                zetten.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Selecteer overige abonnementen</div>
              <p className="text-base text-plum">
                Aan de hand van de ingevulde gegevens kunnen we gelijk meerdere organisaties
                informeren.
              </p>
            </div>
          </div> */}
        </div>
      </Section>
      <div className="flex justify-center bg-darkwhite">
        <DashedLine3 />
      </div>
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll grid grid-cols-1 gap-16 lg:grid-cols-2">
          <div className="mb-12 max-w-3xl space-y-4">
            <div className="font-header text-4xl text-blue">
              Einde aan de administratieve rompslomp
            </div>
            <p className="text-lg text-plum">
              Closure is dat extra stukje dienstverlening dat Translink wilt meegeven aan
              nabestaanden, aangezien zij het al moeilijk genoeg hebben. Daarnaast zien ze Closure
              als manier om nabestaanden te helpen herinneren dat wanneer zij zelf ook ooit
              wijzigingen willen aanbrengen aan hun OV-chipkaartaccount, ze zonder te veel
              administratieve rompslomp door Translink zullen worden bijgestaan. Door de
              samenwerking met Closure is Translink nu in staat om nabestaanden zo goed mogelijk van
              dienst te zijn bij de afwikkeling van een OV-chipkaartaccount van een overleden
              persoon. Translink heeft door de dienst van Closure de klantvriendelijkheid kunnen
              verhogen en een einde kunnen maken aan de administratieve rompslomp.
            </p>
          </div>
          <div className="flex items-center justify-center">
            <div className="space-y-4">
              <div className="font-header text-2xl italic leading-snug text-darkblue lg:text-4xl">
                &quot;Allesomvattend is Closure dat extra stukje dienstverlening dat wij willen
                meegeven aan nabestaanden. Zij hebben het al moeilijk genoeg.&quot;
              </div>
              <div className="space-y-2">
                <div className="text-xl font-medium text-blue">Jokelien Vles</div>
                <div className="text-lg text-plum">Adviseur reizigerscommunicatie @ Translink</div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <div className="flex justify-center bg-darkwhite">
        <DashedLine4 />
      </div>
      {/* <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll">
          <div className="mx-auto mb-12 max-w-xl text-center font-header text-5xl text-blue">
            De impact
          </div>
          <div className="grid grid-cols-1 gap-4 gap-y-8 md:gap-8 lg:grid-cols-3 lg:gap-y-0">
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Vind ssd</div>
              <p className="text-base text-plum">
                Vind een partner op onze partner pagina waarvan jij het abonnement wilt opzeggen of
                overzetten.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Vul het webformulier in</div>
              <p className="text-base text-plum">
                Vul eenmalig de vereiste informatie in om het abonnement op te zeggen of over te
                zetten.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4 bg-offwhite p-14">
              <div className="text-xl text-blue">Selecteer overige abonnementen</div>
              <p className="text-base text-plum">
                Aan de hand van de ingevulde gegevens kunnen we gelijk meerdere organisaties
                informeren.
              </p>
            </div>
          </div>
        </div>
      </Section> */}
      <Section bgColor="bg-darkwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="js-show-on-scroll grid grid-cols-1 gap-16 lg:grid-cols-2">
          <div className="mb-12 max-w-3xl space-y-4">
            <div className="font-header text-4xl text-blue">Resources</div>
            <p className="text-lg text-plum">
              Met de OV-chipkaart kun je betalen in het Openbaar Vervoer (OV).
            </p>
            <ul className="space-y-4">
              <li className="flex items-center justify-start space-x-4">
                <ComputerDesktopIcon className="h-6 w-6 text-blue" />
                <a
                  href="https://ov-chipkaart.nabestaanden.support/beeindigen/gegevens-overledene"
                  className="text-blue"
                >
                  Bekijk hier de overlijdenspagina van OV-Chipkaart.nl
                </a>
                <ArrowTopRightOnSquareIcon className="h-5 w-5 text-blue" />
              </li>
              <li className="flex items-center justify-start space-x-4">
                <ComputerDesktopIcon className="h-6 w-6 text-blue" />
                <a
                  href="https://www.linkedin.com/pulse/wij-voelen-ons-verantwoordelijk-om-ook-nabestaanden-zo-carlijn-soer/"
                  className="text-blue"
                >
                  Bekijk hier het volledige interview met Jokelien van OV-Chipkaart
                </a>

                <ArrowTopRightOnSquareIcon className="h-5 w-5 text-blue" />
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-center">
            <div className="space-y-4">
              <div className="font-header text-3xl italic leading-snug text-darkblue lg:text-4xl">
                &quot;Het OV is van iedereen en als Translink hebben we een grote maatschappelijke
                rol. Zodoende voelen wij ons verantwoordelijk om ook nabestaanden zo goed mogelijk
                van dienst te zijn.&quot;
              </div>
              <div className="space-y-2">
                <div className="text-xl font-medium text-blue">Jokelien Vles</div>
                <div className="text-lg text-plum">Adviseur reizigerscommunicatie @ Translink</div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export default OvChipkaart;

export function Head(props: HeadProps) {
  const { location } = props;
  return (
    <HeadParent pageTitle="OV Chipkaart Case Study" location={location}>
      <meta name="description" content="Description ov-chipkaart case study" />
    </HeadParent>
  );
}
