import React from 'react';

function PersMediaSEO() {
  return (
    <meta
      name="description"
      content="Closure is een award winning Nederlandse scale-up. Met onze maatschappelijke rol helpen wij nabestaanden vanuit een unieke en vaak onbekende hoek."
    />
  );
}
function PersMediaSEOFRBE() {
  return (
    <meta
      name="description"
      content="Closure est une scale-up néerlandaise primée. De par notre rôle social, nous aidons les proches sous un angle unique et souvent méconnu."
    />
  );
}

export { PersMediaSEO, PersMediaSEOFRBE };
