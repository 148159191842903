import { HeadProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FooterCTA from '../../components/FooterCTA';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import SimpleHeader from '../../components/SimpleHeader';
import {
  AlgemeneVoorwaardenSEO,
  AlgemeneVoorwaardenSEOFRBE
} from '../../content/seo/algemene-voorwaarden';
import HeadParent from '../../utils/header';

function AlgemeneVoorwaarden({ pageContext }) {
  const { locale } = pageContext;
  let link = '';
  switch (locale) {
    case 'nl-NL':
      link = 'https://closure.nl/GebruiksvoorwaardenClosure.pdf';
      break;
    case 'fr-BE':
      link = 'https://closure.fr/GebruiksvoorwaardenClosureBE.pdf';
      break;
    case 'nl-BE':
      link = 'https://closure.be/GebruiksvoorwaardenClosureBE.pdf';
      break;
    default:
      link = 'https://closure.nl/GebruiksvoorwaardenClosure.pdf';
      break;
  }
  return (
    <Layout pageContext={pageContext} navColor="bg-darkwhite" navTextColor="dark">
      <SimpleHeader
        title={<FormattedMessage id="tos" />}
        lastUpdated={<FormattedMessage id="updateTOSDate" />}
      />
      <Section bgColor="bg-offwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="mx-auto max-w-7xl px-8">
          <a href={link}>
            <div className="flex w-72 items-center justify-between rounded-lg border border-gray-100 p-4">
              <div className="flex h-8 w-8 items-center justify-center p-1">
                <StaticImage src="../../images/pdf_file.png" alt="pdf icon" />
              </div>
              <div>
                <div className="text-sm font-medium text-plum">
                  <FormattedMessage id="tos" />
                </div>
                <div className="text-sm text-plum">
                  <FormattedMessage id="updateTOSDate" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </Section>
      <FooterCTA bgColor="bg-darkwhite" />
    </Layout>
  );
}

export default AlgemeneVoorwaarden;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  let pageTitle = '';
  switch (locale) {
    case 'nl-NL':
      pageTitle = 'Algemene voorwaarden';
      break;
    case 'fr-BE':
      pageTitle = 'Conditions générales';
      break;
    case 'nl-BE':
      pageTitle = 'Algemene voorwaarden';
      break;
    default:
      pageTitle = 'Algemene voorwaarden';
      break;
  }
  return (
    <HeadParent pageTitle={pageTitle} location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <AlgemeneVoorwaardenSEO />}
      {locale === 'fr-BE' && <AlgemeneVoorwaardenSEOFRBE />}
    </HeadParent>
  );
}
