import { LocalizedLink } from 'gatsby-plugin-i18n-l10n';
import React from 'react';
import { useIntl } from 'react-intl';

import { classNames } from '../../utils/helpers';

interface ButtonProps {
  link?: string;
  variant: 'link' | 'modal' | 'external';
  color: 'blue' | 'white';
  rounded?: 'none' | 'small' | 'normal';
  type: 'primary' | 'secondary';
  children: string | JSX.Element;
  modal?: Function;
  fullWidth?: boolean;
}

function Button({
  link,
  variant,
  color,
  rounded,
  type,
  children,
  modal,
  fullWidth = false
}: ButtonProps) {
  let baseStyles = 'text-sm font-medium text-center';
  let colorStyles = '';
  let roundedStyles = '';
  const intl = useIntl();

  switch (type) {
    case 'primary':
      baseStyles += ' px-10 py-5 lg:px-7 lg:py-3';
      break;
    case 'secondary':
      baseStyles += ' px-7 py-3';
      break;
    default:
      baseStyles += ' px-7 py-3';
      break;
  }
  switch (color) {
    case 'blue':
      colorStyles = 'bg-blue text-offwhite';
      break;
    case 'white':
      colorStyles = 'bg-offwhite text-blue';
      break;
    default:
      colorStyles = 'bg-blue text-offwhite';
      break;
  }

  switch (rounded) {
    case 'none':
      roundedStyles = 'rounded-none';
      break;
    case 'small':
      roundedStyles = 'rounded-sm';
      break;
    case 'normal':
      roundedStyles = 'rounded-lg';
      break;
    default:
      roundedStyles = 'rounded-full';
  }

  if (fullWidth) {
    baseStyles += ' w-full';
    roundedStyles = 'rounded-xl';
  }

  if (variant === 'modal' && modal) {
    return (
      <button
        type="button"
        onClick={() => {
          modal();
        }}
        className={classNames(baseStyles, colorStyles, roundedStyles)}
      >
        {children}
      </button>
    );
  }

  if (variant === 'link' && link) {
    return (
      <LocalizedLink to={intl.formatMessage({ id: `url.${link}` })}>
        <button type="button" className={classNames(baseStyles, colorStyles, roundedStyles)}>
          {children}
        </button>
      </LocalizedLink>
    );
  }

  if (variant === 'external' && link) {
    return (
      <a href={link}>
        <button type="button" className={classNames(baseStyles, colorStyles, roundedStyles)}>
          {children}
        </button>
      </a>
    );
  }
  return null;
}

export default Button;
