import React from 'react';

function UitvaartOndernemersSEO() {
  return (
    <meta
      name="description"
      content="Closure heeft samen met de uitvaartsector Nabestaandenloket ontwikkeld om zowel de opzegdienst als andere administratieve zaken aan nabestaanden aan te bieden."
    />
  );
}

export default UitvaartOndernemersSEO;
