import React from 'react';

function OrganizationSEO() {
  return (
    <meta
      name="description"
      content="Closure levert altijd een first-time-right notificatie van een overlijden bij service providers. Hiermee besparen wij operationele kosten en verhogen wij de NPS van organisaties."
    />
  );
}

function OrganizationSEOFRBE() {
  return (
    <meta
      name="description"
      content="La fermeture fournit toujours une notification immédiate d'un décès aux prestataires de services. Cela nous permet d'économiser des coûts opérationnels et d'augmenter le NPS des organisations."
    />
  );
}

export { OrganizationSEO, OrganizationSEOFRBE };
