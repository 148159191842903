import { LanguageSwitcher, LocalizedLink } from 'gatsby-plugin-i18n-l10n';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Popover, Transition } from '@headlessui/react';
import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';

import { mobileNavItems, oplossingen } from '../../../content/data/navigation';
import { ReactComponent as LogoGreen } from '../../../images/svg/green.svg';
import { ReactComponent as LogoWhite } from '../../../images/svg/white.svg';
import { classNames, getFeatureFlags } from '../../../utils/helpers';
import Button from '../../Button';

export interface CTAProps {
  link?: string;
  text: string | JSX.Element;
  modal?: Function;
  https?: boolean;
}

interface NavProps {
  cta?: CTAProps;
  navColor: string;
  navTextColor: string;
  pageContext: {
    locale: string;
    prefix: string;
  };
}

function Nav({ cta, navColor, navTextColor, pageContext }: NavProps) {
  const [y, setY] = useState(0);
  const [navOpen, setNavOpen] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(false);
  const featureFlags = getFeatureFlags(pageContext.locale);
  const intl = useIntl();
  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setScrollingUp(true);
      } else if (y < window.scrollY) {
        setScrollingUp(false);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setY(window.scrollY);
    }
  }, []);

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <Popover
      className={classNames(
        navOpen
          ? 'sticky top-0 shadow-none '
          : scrollingUp
          ? 'sticky top-0'
          : 'sticky -top-20 shadow-none sm:-top-20',
        y < 80
          ? `shadow-none ${navColor}`
          : navColor === 'bg-primary' && y < 160
          ? 'bg-primary shadow-sm'
          : 'bg-offwhite shadow-sm',
        'bg-beige z-20 transition-all duration-100 ease-in-out'
      )}
    >
      {({ open }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          setNavOpen(open);
        }, [open]);
        return (
          <header
            className={classNames(
              open ? 'bg-white' : 'sticky top-0',
              'delay-50 transition-colors duration-200'
            )}
          >
            <nav className="mx-auto max-w-7xl px-0 md:px-4">
              <div className="flex h-20 w-full items-center justify-between">
                <div className="flex items-center pl-4 md:pl-0">
                  <LocalizedLink to="/">
                    {open ? (
                      <LogoGreen className="h-4 w-auto md:h-6" />
                    ) : navTextColor === 'white' && y < 160 ? (
                      <LogoWhite className="h-4 w-auto md:h-6" />
                    ) : (
                      <LogoGreen className="h-4 w-auto md:h-6" />
                    )}
                  </LocalizedLink>
                </div>

                <div className="flex items-center space-x-4 pr-4 lg:hidden">
                  <LanguageSwitcher
                    className={classNames(
                      'sm pl-12 text-sm font-medium',
                      navTextColor === 'dark' ? 'text-plum' : 'text-darkwhite hover:text-blue'
                    )}
                    resolveLanguageName={locale =>
                      intl.formatMessage({ id: `languages.${locale}` })
                    }
                  />
                  {open ? (
                    <Popover.Button
                      className={classNames(
                        open
                          ? 'bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500'
                          : 'bg-offwhite  text-plum hover:text-plum-200',
                        'focus:none inline-flex items-center justify-center rounded-md p-2'
                      )}
                    >
                      <XMarkIcon className="h-6 w-6" />
                    </Popover.Button>
                  ) : (
                    <Popover.Button
                      className={classNames(
                        open
                          ? 'bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500'
                          : 'bg-offwhite text-plum hover:bg-darkwhite hover:text-plum-200',
                        'focus:none inline-flex items-center justify-center rounded-md p-2'
                      )}
                    >
                      <Bars3Icon className="h-6 w-6" />
                    </Popover.Button>
                  )}

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute inset-0 z-10 mt-20 h-screen w-screen transform">
                      <div className="absolute inset-0 bg-white px-4 pb-20 pt-0">
                        {mobileNavItems.map((navItemGroup, indexHeaders) => (
                          <div
                            key={navItemGroup.id}
                            className={classNames(indexHeaders > 0 ? 'pt-6' : '', 'pb-2')}
                          >
                            <div className="pb-2 text-lg font-medium text-darkblue">
                              {navItemGroup.placeholder}
                            </div>
                            {navItemGroup.navItems.map(navItem => {
                              if (
                                navItem.url === 'uitvaart-ondernemers' &&
                                featureFlags['uitvaart-ondernemers'] === false
                              ) {
                                return null;
                              }
                              if (
                                navItem.url === 'case-studies' &&
                                featureFlags['case-studies'] === false
                              ) {
                                return null;
                              }
                              return (
                                <LocalizedLink
                                  key={navItem.id}
                                  to={intl.formatMessage({ id: `url.${navItem.url}` })}
                                  className="flex h-10 flex-row items-center text-sm text-plum hover:text-plum-200 lg:text-base"
                                >
                                  {navItem.name}
                                </LocalizedLink>
                              );
                            })}
                          </div>
                        ))}
                        {/* TODO : ADD CLICKABLE FUNCTION */}
                        {cta && cta.modal && (
                          <Button
                            variant="modal"
                            type="primary"
                            color="blue"
                            modal={cta.modal}
                            fullWidth
                          >
                            {cta.text}
                          </Button>
                        )}
                        {cta && cta.link && (
                          <Button
                            variant={
                              cta.link && cta.link.includes('http')
                                ? 'external'
                                : cta.https
                                ? 'external'
                                : 'link'
                            }
                            type="primary"
                            color="blue"
                            link={intl.formatMessage({ id: cta.link })}
                            fullWidth
                          >
                            {cta.text}
                          </Button>
                        )}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </div>
                <div className="hidden items-center space-x-8 text-sm md:ml-10 lg:flex">
                  <span>
                    <LanguageSwitcher
                      className={classNames(
                        'sm pl-12 text-sm font-medium',
                        navTextColor === 'dark' ? 'text-plum' : 'text-darkwhite hover:text-blue'
                      )}
                      resolveLanguageName={locale =>
                        intl.formatMessage({ id: `languages.${locale}` })
                      }
                    />
                  </span>
                  {oplossingen.map(oplossing => {
                    if (
                      oplossing.url === 'uitvaart-ondernemers' &&
                      featureFlags['uitvaart-ondernemers'] === false
                    ) {
                      return null;
                    }
                    if (
                      oplossing.url === 'case-studies' &&
                      featureFlags['case-studies'] === false
                    ) {
                      return null;
                    }
                    return (
                      <LocalizedLink
                        key={oplossing.id}
                        className={classNames(
                          y > 160
                            ? 'text-plum hover:text-darkblue-300'
                            : navTextColor === 'dark'
                            ? 'text-plum hover:text-darkblue-300'
                            : 'text-offwhite hover:text-plum'
                        )}
                        to={intl.formatMessage({ id: `url.${oplossing.url}` })}
                        activeClassName="text-plum font-medium"
                      >
                        {oplossing.name}
                      </LocalizedLink>
                    );
                  })}
                  {cta && cta.modal && (
                    <Button variant="modal" type="primary" color="blue" modal={cta.modal}>
                      {cta.text}
                    </Button>
                  )}
                  {cta && cta.link && (
                    <Button
                      variant={
                        cta.link && cta.link.includes('http')
                          ? 'external'
                          : cta.https
                          ? 'external'
                          : 'link'
                      }
                      type="primary"
                      color="blue"
                      link={intl.formatMessage({ id: cta.link })}
                    >
                      {cta.text}
                    </Button>
                  )}
                </div>
              </div>
            </nav>
          </header>
        );
      }}
    </Popover>
  );
}

export default Nav;
