import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Disclosure } from '@headlessui/react';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';

import { classNames } from '../../utils/helpers';
import Section from '../Section';
import Title from '../Typography/Title';

export interface QuestionProps {
  question: string;
  answer: string;
  id: number;
}

export interface FAQProps {
  questions: QuestionProps[];
}

export default function FAQ({ questions }: FAQProps) {
  return (
    <Section paddingTop="pt-16 lg:pt-28" paddingBottom="pb-16 lg:pb-40" bgColor="bg-blue">
      <div className="mx-auto max-w-7xl lg:px-0">
        <div className="mb-8 text-center">
          <Title color="light">
            <FormattedMessage id="faqTitle" />
          </Title>
        </div>
        <div className="mx-auto max-w-3xl">
          <dl className="mt-6 space-y-6 divide-y-2 divide-blue-600">
            {questions.map(faq => (
              <Disclosure as="div" key={faq.id} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-offwhite">
                        <span className="text-lg font-medium text-darkwhite">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-offwhite">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </Section>
  );
}
