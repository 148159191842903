const messages = {
  'languages.nl-NL': 'Nederlands',
  'languages.nl-BE': 'Nederlands',
  'languages.fr-BE': 'Français',
  'languages.fr-FR': 'Français',
  uo: 'Funeral organizers',
  heir: 'Bereaved',
  company: 'Service providers',
  knowledgeBase: 'Knowledge Base',
  caseStudies: 'Case Studies',
  contact: 'Contact',
  solutions: 'Solutions',
  resources: 'Resources',
  partners: 'Partners',
  aboutUs: 'About us',
  legal: 'Legal',
  team: 'Team',
  media: 'Press',
  vacancies: 'Vacancies',
  privacy: 'Privacy Policy',
  cookies: 'Cookie Policy',
  tos: 'Terms of Service',
  homeHeaderTitle: 'The specialised bereavement desk for your organisation',
  homeHeaderSubTitle:
    'Please select the option that suits you the most and see how we can help you.',
  homeHeaderButtonHeir: 'Bereaved',
  homeCard1Title: 'I want to finalise subscriptions as a bereaved individual.',
  homeCard1Body: 'Not implemented',
  homeCard2Title: 'How can we provide the right bereavemenet support as a service provider?',
  homeCard2Body: 'Not implemented',
  homeCard3Title:
    'How can we lower our operational costs while maintaining quality as a service provider?',
  homeCard3Body: 'Not implemented',
  homeCard4Title: 'How do I deal with the digital legacy of my customers?',
  homeCard4Body: 'Not implemented',
  homeCard5Title: 'I want to provide my customers an aftercare solution',
  homeCard5Body: 'Not implemented',
  homeCta1Heirs: 'Abonnementen opzeggen',
  homeCtaHowItWorks: 'Hoe werkt het?',
  homeCta1Company: 'Bezoek de corporate pagina',
  homeCtaDemo: 'Plan een Demo',
  homeCtaNBL: 'Bezoek Bezoek Nabestaandenloket.nl',
  homeCtaMoreInfo: 'Meer informatie',
  heirHeaderTitle: 'A simple & secure death notification service to inform all organisations',
  heirHeaderSubTitle:
    "When a loved one has died, use Closure's death notification service to inform organisations for free.",
  heirHeaderCta1: 'Find a provider',
  heirHeaderCta2: 'How does it work?',
  heirHowItWorksTitle: 'How does it work?',
  heirHowItWorksSubTitle:
    'Using Closure, you only need to provide information once at one of our providers. Then, select all organisations you would like to inform and we will do the rest.',
  heirHowItWorksStep1Title: 'Find a provider',
  heirHowItWorksStep1Body:
    "Find one of our partners that offer Closure's service and use the organisation as starting point.",
  heirHowItWorksStep2Title: 'Fill digital webform',
  heirHowItWorksStep2Body:
    'Provide the information of the deceased person through a digital form. We will store the information securely.',
  heirHowItWorksStep3Title: 'Inform all organisations',
  heirHowItWorksStep3Body:
    'Select all organisations that you would like to inform of the death. We will keep you updated weekly with the finalisation process.',
  heirCtaBoxTitle: 'Find a provider',
  heirCtaBoxBody:
    'We are able to finalise subscriptions are more than 1600 organisations. Through one of our official partners you can start using the Closure death notification service for free. Click on the button below to find your starting point to inform all organisations at once.',
  heirCtaBoxButton: 'Browse through providers',
  companyHeaderTitle: 'Simplifying the death notification process for subscription providers',
  companyHeaderSubTitle:
    "Closure's award winning solution minimises the administrative burden on your bereaved customers, digitises and streamlines your bereavement processes and delivers efficiency, 5-star customer experience and cost savings.",
  companyHeaderCta1: 'Book a demo',
  companyHeaderCta2: 'How does it work?',
  uspTitle: 'See the benefits of our death notification solution for your industry.',
  uspChooseIndustry: 'Select your industry',
  uspCompanyTitle: 'For your organisation',
  uspHeirTitle: 'For your customers',
  uspHowItWorksTitle: 'How does it work?',
  uspHowItWorksBody:
    'We work with you to understand your bereavement journey, identifying the exact information and documentation required to effectively resolve a bereavement case for each of your products and services.',
  uspHowItWorksStep1Title: 'Digital form',
  uspHowItWorksStep1Body:
    'Your requirements are mapped into a digital webform to handle finalising or transfers of your products or services. We are also able to serve your customers as your digital bereavement support desk through phone.',
  uspHowItWorksStep2Title: 'Death notification validation',
  uspHowItWorksStep2Body:
    'Our intelligent software and bereavement support desk will handle and validate the notifications. A standardised and first-time-right notification is generated an transferred to your organisation by your wills.',
  uspHowItWorksStep3Title: 'Death notification processed',
  uspHowItWorksStep3Body:
    'Based on the type of IT integration, we are able to send you a first-time-right death notification in the preferred format or through an API integration. In both cases, a completed death notification is provided saving you time.',
  uspTestimonialTitle: 'Testimonials',
  uspTestimonialSubTitle: 'Both organisations and bereaved individuals recommend Closure.',
  uspTestimonial1Title: 'Customer of Odido',
  uspTestimonial1Body:
    'It was very pleasing that I could easily manage my subscriptions of my deceased husband at Odido. What was even greater, that they allowed me to inform other organisations at teh same time.',
  uspTestimonial2Title: 'Farid, Manager at DPG Media',
  uspTestimonial2Body:
    'It was very pleasing that I could easily manage my subscriptions of my deceased husband at Odido. What was even greater, that they allowed me to inform other organisations at teh same time.',
  uspTestimonial3Title: 'Customer of DPG Media',
  uspTestimonial3Body:
    'It was very pleasing that I could easily manage my subscriptions of my deceased husband at Odido. What was even greater, that they allowed me to inform other organisations at teh same time.',
  uspTestimonial4Title: 'Bas Jansen, Odido',
  uspTestimonial4Body:
    'Closure helped both ourselves and our customers. We get high quality first time right notifications while increasing customer satisfaction.',
  uspTypeIntegrationTitle: 'Type of integration',
  uspTypeIntegrationLow: 'Zero impact IT',
  uspTypeIntegrationMedium: 'Medium impact IT',
  uspTypeIntegrationHigh: 'High impact IT',
  uspTypeIntegrationLowTitle: 'Minimal integrated IT Solution',
  uspTypeIntegrationLowBody:
    'We weten dat corporate IT roadmaps complex zijn. Daarom kan de Closure service in uw organisatie geïmplementeerd worden zonder dat inzet nodig is van uw IT afdeling. Wij sturen u geverifieerde first-time-right overlijdensmeldingen via gestandaardiseerde e-mails. Door uw processen door en door te begrijpen, halen we de pijn weg van het verifieren van persoonsgegevens, documentcontroles en het uitsturen van repetitieve herinneringen om foutieve gegevens te corrigeren.',
  uspTypeIntegrationMediumTitle: 'Medium integrated IT Solution',
  uspTypeIntegrationMediumBody:
    'To provide a more efficient workflow, it is possible for us to upload verified death notifications into a data source, which is easily retrievable by your IT department to process the death notification.',
  uspTypeIntegrationHighTitle: 'Integrated IT Solution',
  uspTypeIntegrationHighBody:
    'Through a custom-built API connection we are able to directly process the death notification in your IT systems, removing all required handlings from your organisation in the workflow.',
  companyFooterCtaTitle: 'Book a demo',
  companyFooterCtaBody:
    "Book a free consultation meeting with us to see how Closure can help improve your organisation's bereavement journey.",
  companyFooterCtaButton: 'Plan a demo',
  modalDemoTitle: 'Plan a demo',
  modalDemoBody:
    "See how we can help with your organisation's bereavement journey. We will get back to you as soon as possible and schedule a meeting.",
  modalDemoEmail: 'E-mail adres',
  modalDemoName: 'Full name',
  modalDemoCompany: 'Company',
  modalDemoButton: 'Book a demo',
  uoHeaderTitle: 'Nabestaandenloket.nl supports your customers as an all-in-one aftercare service.',
  uoHeaderSubTitle:
    'Nabestaandenloket was created to combine all aftercare services into one product which is extended by a premium aftercare supportdesk.',
  uoHeaderButton: 'Visit Nabestaandenloket.nl',
  uoServiceTitle: 'An all-in-one after care solution tailored to your funeral organisation.',
  uoServiceBody:
    'Nabestaandenloket is tailored to your funeral organisation so you can provide the best solutions to your customers.',
  uoService1Title: 'Subscription service',
  uoService1Body:
    "The Nabestaandenloket platform includes Closure's death notification service. Let your customers easily finalise all subscriptions in one central platform.",
  uoService2Title: 'Extend your service',
  uoService2Body:
    'All necessary administrative tasks that must be done after a death presented in an easy-to-use platform. Let your customers manage the tasks or delegate tasks to other relatives.',
  uoService3Title: 'Support service',
  uoService3Body:
    'Our specialised support desk is available through phone, chat and e-mail for your customers with questions with regards to the aftercare.',
  uoFeature1Title: 'The closure death notification service',
  uoFeature1Body:
    "The Closure's death notification service is included in the Nabestaandenloket platform. This makes it easy for your customers to take care of all subscriptions within the Nabestaandenloket platform.",
  uoFeature1Button: 'Watch a demo',
  uoFeature2Title: 'Easy manage all administrative tasks',
  uoFeature2Body:
    'All administrative tasks that need to be done after a death are organised per sector. For each task clear guidance and information is provided to your customers.',
  uoFeature2Button: 'Watch a demo',
  uoFeature3Title: 'Online memorial page',
  uoFeature3Body:
    'A tailored memorial page that enables more engagement and funeral support for your customer. Allow relatives to share memories and provide the necessary information around the funeral.',
  uoFeature3Button: 'Watch a demo',
  uoSecondaryBenefitsTitle: 'Why choose Nabestaandenloket?',
  uoSecondaryBenefits1Title: 'Closure service included',
  uoSecondaryBenefits1Body: 'Description',
  uoSecondaryBenefits2Title: 'In your own branding',
  uoSecondaryBenefits2Body: 'Description',
  uoSecondaryBenefits3Title: 'Extend service beyond the funeral',
  uoSecondaryBenefits3Body: 'Description',
  uoSecondaryBenefits4Title: 'Administrative tasks',
  uoSecondaryBenefits4Body: 'Description',
  uoSecondaryBenefits5Title: 'Online Memorial Page',
  uoSecondaryBenefits5Body: 'Description',
  uoSecondaryBenefits6Title: 'Specialised support desk',
  uoSecondaryBenefits6Body: 'Description',
  uoSecondaryBenefits7Title: 'Multi-user portal',
  uoSecondaryBenefits7Body: 'Description',
  uoSecondaryBenefits8Title: 'Easy integration',
  uoSecondaryBenefits8Body: 'Description',
  uoSecondaryBenefits9Title: 'User analytics',
  uoSecondaryBenefits9Body: 'Description',
  uoNBLDeskTitle: 'A specialised bereavement support desk to help your customers with aftercare',
  uoNBLDeskBody:
    'Our bereavemend support desk is available by phone, email and chat on your Nabestaandenloket platform from monday to friday (08:30 - 21:00) and saturday (09:00 - 13:00).',
  uoNBlDeskBenefitFeature1: 'Real specialised bereavement support desk.',
  uoNBlDeskBenefitFeature2: 'Open from ma-fr (08:30 - 21:00) and saturday (09:00 - 13:00).',
  uoNBlDeskBenefitFeature3: 'Available through chat, e-mail and phone.',
  teamHeaderCategory: 'Our mission',
  teamHeaderTitle:
    'Finalising subscriptions, contracts and accounts after a passing made easy, not only for heirs, but also for service providers.',
  teamOurStoryCategory: 'Our story',
  teamOurStoryTitle: 'It all started when..',
  teamOurStoryBody:
    'There are tools out there to help homeowners get a mortgage, but after move-in, it&apos;s a complete black box. Escrow? Refis? Insurance? It can all get a little overwhelming — especially considering the home is most people&apos;s biggest financial asset. This is something we experienced ourselves as first-time buyers, inspiring us to create Haven. By connecting homeowners and mortgage companies via a modern, easy-to-use digital platform, Haven makes home financing simple and intuitive.',
  teamStat1Value: '100,000+',
  teamStat1Title: 'Subscriptions finalised since 2015',
  teamStat2Value: '9.6',
  teamStat2Title: 'NPS Score',
  teamStat3Value: 'Part of Goldman Sachs Launch',
  teamStat3Title: 'Part of Goldman Sachs Launch Program 2022',
  teamSectionTitle: 'Meet the team trying to improve the digital bereavement journey.',
  teamHiringTitle: 'Join Closure',
  teamHiringBody:
    'We value partnership, creativity, and growth for each other and the people we serve. Join a fast-growing scale-up and find your place.',
  teamHiringFeature1: 'Remote & office options',
  teamHiringFeature2: 'A-player team',
  teamHiringFeature3: 'Opportunities',
  teamHiringButton: 'View positions',
  mediaHeaderTitle: 'Press & Media',
  mediaArticleButton: 'Read more',
  partnersHeaderTitle: 'Find a provider',
  partnerPlaceHolder: "For example 'ING'",
  partnersHeaderBody:
    'Find an organisation that provides the Closure death notification service. Through this provider you are able to cancel all subscriptions in one go. ',
  partnersNoSearchResults: 'No search results found',
  partnersNoSearchResultsInput:
    "Please let us know if you can't find an organisation that you would like to inform. We will try to add the organisation and let you know as soon as it is possible.",
  partnersNoSearchResultsButton: 'Submit',
  partnersCardButtonBegin: 'Start now',
  partnersCardButtonMoreInfo: 'More information',
  partnersModalInfoTitle: 'Information on unofficial partners',
  partnersModalInfoBody:
    "You have clicked on an unonfficial partners. Don't worry! We are still able to cancel this subscription for you. However, you should first start the closure process by closing or transferring your subscription of one of our official partners. Then, you can easily select this organisation as well. ",
  partnersModalInfoButton: 'Close',
  contactCategory: 'Contact us',
  contactHeaderTitle: 'Get in touch with our team. We’d be happy to lend a helping hand',
  contactHeirTitle: 'Bereaved support',
  contactHeirBody:
    'Do you have any questions regarding the Closure death notification service? We are here to help you.',
  contactHeirButton: 'Chat us',
  contactHeirOptional: 'or call',
  contactSalesTitle: 'Service provider contact',
  contactSalesBody:
    'Leave your contact information behind and we wil get back to you as soon as possible.',
  contactSalesButton: 'Book a demo',
  contactMediaTitle: 'Press',
  contactMediaBody:
    'If you have any press enquiries please send us an email at press@closure.nl. Thank you!',
  contactMediaButton: 'Mail',
  caseStudiesHeaderTitle: 'Case studies',
  caseStudiesHeaderBody: 'Find case studies that we have done with our partners. ',
  readmore: 'Read more',
  newsletter: 'Sign up for our newsletter',
  afm: 'Closure has a license from the Netherlands Authority for the Financial Markets (AFM) for reporting death to financial service providers.',
  copyright:
    'Closure B.V. / Stationsplein 45 - Space A7.209 / 3013AM Rotterdam KVK: 70792828 © Closure 2023. All Rights Reserved.',
  fullscreenMenuButton: 'Book a demo',
  storyLinesPartnersTitle: 'Our partners',
  storyLinesPartnersBody: 'zie hier onze partners',
  storyLinesBottomCtaTitle: 'Learn more about corporate now',
  storyLinesBottomCtaBody: 'Average amount saved on operational costs',
  storyLinesBottomCtaButton: 'Learn more',
  emailPlaceholder: 'Email address',
  namePlaceholder: 'Full name',
  companyPlaceholder: 'Company name',
  organisationsSearchPlaceholder: 'Zoek naar organisaties',
  faqTitle: 'Frequently asked questions',
  uoFooterCTATitle: 'Visit Nabestaandenloket.nl',
  uoFooterCTABody:
    'Plan a free demo to see how Nabestaandenloket can extend your services as a funeral organisation.',
  uoFooterCTAButton: 'Visit Nabestaandenloket.nl',
  uoCTA2Text: 'or call',
  uoPhoneNumber: '085 06 45 744',
  tMobileCaseStudySummary:
    "'Je wilt met zo’n gevoelig onderwerp mensen niet onnodig laten wachten, of ook maar iéts verkeerd doen' Bas Jansen, Lead Contact Center'",
  ovChipkaartCaseStudySummary: 'Seea',
  DPGMediaCaseStudySummary: 'sdasds',
  newsletterModalTitle: 'Newsletter Closure',
  newsletterModalBody:
    'Subscribe to our newsletter to recieve the latest updates and news about our services.',
  newsletterButton: 'Sign up',
  alreadyAccount: 'Or login if you already have an account',
  searchPartnersPlaceholder: 'Search partners',
  backToHome: 'Back to homepage',
  teamSectionGoldmanSachs: 'We are part of the Goldman Sachs Launch Program',
  teamSectionPartnersProgram: 'We have proudly collaborated with several prestiguous',
  bannerText: 'Dit is de banner text die we kunnen instellen',
  organisationPlaceholder: 'organisatie',
  officialPartners: 'Klik hier voor de officiele partners',
  errorTitlePartners: 'Oh, no something went wrong',
  errorBodyPartners: 'Refresh the page ',
  phonenumber: 'Telefoonnummer',
  errorMsgDemo: 'Please fill in all required fields',
  successMsgDemo: 'Successfully responded'
};

export default messages;
