import React from 'react';

function PartnersSEO() {
  return (
    <meta
      name="description"
      content="Met de dienstverlening van Closure is het mogelijk om het abonnement bij meer dan 1600 orgnisaties op te zeggen of over te zetten."
    />
  );
}

function PartnersSEOFRBE() {
  return (
    <meta
      name="description"
      content="Avec les services de Closure, il est possible d'annuler ou de transférer l'abonnement à plus de 1600 organisations."
    />
  );
}

export { PartnersSEO, PartnersSEOFRBE };
