/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import React, { createRef, Fragment, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage, useIntl } from 'react-intl';

import { Dialog, Transition } from '@headlessui/react';

export interface ModalDemoProps {
  isOpen: boolean;
  setIsOpen: Function;
}

export interface IFormDemo {
  name: string;
  organization: string;
  email: string;
  phonenr?: string;
  reason?: string;
  'opt-in-news-letter'?: boolean;
}

export default function ModalDemo({ isOpen, setIsOpen }: ModalDemoProps) {
  const intl = useIntl();
  const recaptchaRef = createRef();
  const [error, setError] = useState({
    error: false,
    msg: ''
  });
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState<IFormDemo>({
    name: '',
    organization: '',
    email: '',
    phonenr: '',
    reason: '',
    'opt-in-news-letter': false
  });

  const handleChange = e => {
    if (e.target.name === 'opt-in-news-letter') {
      setFormData(prev => ({ ...prev, 'opt-in-news-letter': !prev['opt-in-news-letter'] }));
    } else {
      setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };
  const requestDemo = e => {
    let recaptchaValue = null;
    e.preventDefault();
    if (recaptchaRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      recaptchaValue = recaptchaRef.current.execute();
    }
    if (!formData.name || !formData.organization || !formData.email) {
      setError(old => ({ ...old, error: true, msg: 'Please fill in all required fields' }));
      setSuccess(false);
      return false;
    }
    return axios({
      url: `${process.env.GATSBY_API_BASE_URL}/homepage/contact`,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 200) {
          setSuccess(true);
          setError(old => ({ ...old, error: false }));
        }
      })
      .catch(() => {
        setError({ error: true, msg: 'Something went wrong' });
        setSuccess(false);
      });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-screen w-screen transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-lg transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:align-middle md:h-auto">
                <div className="h-full">
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="text-plum-64 rounded-md bg-white hover:text-plum focus:outline-none"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="mx-auto flex max-w-md flex-col py-28 px-6 md:py-20">
                    <Dialog.Title as="h3" className="text-center font-header text-2xl text-blue">
                      <FormattedMessage id="modalDemoTitle" />
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-center text-base text-gray-500">
                        <FormattedMessage id="modalDemoBody" />
                      </p>
                    </div>
                    <form method="post" onSubmit={requestDemo}>
                      {/* <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_CLIENT_KEY}
                        size="invisible"
                      /> */}
                      <div className="mt-8 flex flex-col justify-center space-y-2">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="w-full rounded-md border border-gray-200 py-4 px-6 shadow-sm sm:text-sm"
                          placeholder={intl.formatMessage({ id: 'namePlaceholder' })}
                          onChange={handleChange}
                          value={formData.name}
                        />
                        <input
                          type="text"
                          name="organization"
                          id="organization"
                          className="w-full rounded-md border border-gray-200 py-4 px-6 shadow-sm sm:text-sm"
                          placeholder={intl.formatMessage({ id: 'organisationPlaceholder' })}
                          onChange={handleChange}
                          value={formData.organization}
                        />
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="w-full rounded-md border border-gray-200 py-4 px-6 shadow-sm sm:text-sm"
                          placeholder={intl.formatMessage({ id: 'emailPlaceholder' })}
                          onChange={handleChange}
                          value={formData.email}
                        />
                        <input
                          type="text"
                          name="phonenr"
                          id="phonenr"
                          className="w-full rounded-md border border-gray-200 py-4 px-6 shadow-sm sm:text-sm"
                          placeholder={intl.formatMessage({ id: 'phonenumber' })}
                          onChange={handleChange}
                          value={formData.phonenr}
                        />
                        <input
                          type="text"
                          name="reason"
                          id="reason"
                          className="w-full rounded-md border border-gray-200 py-4 px-6 shadow-sm sm:text-sm"
                          placeholder={intl.formatMessage({ id: 'reason' })}
                          onChange={handleChange}
                          value={formData.reason}
                        />
                        <div className="flex items-center space-x-2 py-2 text-left">
                          <div className="h-8 w-8">
                            <input
                              type="checkbox"
                              name="opt-in-news-letter"
                              id="opt-in-news-letter"
                              className="h-full w-full border border-gray-200 sm:text-sm"
                              placeholder={intl.formatMessage({ id: 'phonenumber' })}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="text-sm text-gray-400">
                            <FormattedMessage id="newsletterModalBody" />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="mt-6 w-full rounded-full bg-blue px-6 py-3 text-white"
                      >
                        <FormattedMessage id="modalDemoButton" />
                      </button>
                      {error.error && (
                        <div className="mt-4 w-full rounded-xl border border-red-200 bg-red-50 p-3 text-sm font-medium text-red-400">
                          {intl.formatMessage({ id: 'errorMsgDemo' })}
                        </div>
                      )}
                      {success && (
                        <div className="mt-4 w-full rounded-xl border border-green-200 bg-green-50 p-3 text-sm font-medium text-green-400">
                          {intl.formatMessage({ id: 'successMsgDemo' })}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
