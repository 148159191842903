/* eslint-disable import/prefer-default-export */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TYPECLIENT } from '../../utils/helpers';

const cards = [
  {
    id: 1,
    text: <FormattedMessage id="homeCard1Title" />,
    body: <FormattedMessage id="homeCard1Body" />,
    image: (
      <StaticImage
        src="../../images/home/stop-subscriptions.png"
        alt="Help with closing subscriptions of heir"
        className="w-52 lg:w-40"
      />
    ),
    type: TYPECLIENT.heir,
    typePlaceholder: <FormattedMessage id="heir" />,
    cta_1: { text: <FormattedMessage id="homeCta1Heirs" />, url: '/nabestaanden' },
    cta_2: { text: <FormattedMessage id="homeCtaHowItWorks" />, url: '/nabestaanden' }
  },
  {
    id: 2,
    text: <FormattedMessage id="homeCard2Title" />,
    body: <FormattedMessage id="homeCard2Body" />,
    image: (
      <StaticImage
        src="../../images/home/right-conversation.png"
        alt="Help organisation with an efficient death notification process"
        className="w-52 lg:w-40"
      />
    ),
    type: TYPECLIENT.company,
    typePlaceholder: <FormattedMessage id="company" />,
    cta_1: { text: <FormattedMessage id="homeCta1Company" />, url: '/organisaties' }
  },
  {
    id: 3,
    text: <FormattedMessage id="homeCard3Title" />,
    body: <FormattedMessage id="homeCard3Body" />,
    image: (
      <StaticImage
        src="../../images/home/customer-service-operational-costs.png"
        alt="Help with lowering customer service operational costs"
        className="w-52 lg:w-40"
      />
    ),
    type: TYPECLIENT.company,
    typePlaceholder: <FormattedMessage id="company" />,
    cta_1: { text: <FormattedMessage id="homeCta1Company" />, url: '/organisaties' }
  },
  {
    id: 4,
    text: <FormattedMessage id="homeCard4Title" />,
    body: <FormattedMessage id="homeCard4Body" />,
    image: (
      <StaticImage
        src="../../images/home/verify-documents.png"
        alt="Help organisation with verification of death documents"
        className="w-52 lg:w-40"
      />
    ),
    type: TYPECLIENT.company,
    typePlaceholder: <FormattedMessage id="company" />,
    cta_1: { text: <FormattedMessage id="homeCta1Company" />, url: '/organisaties' }
  },
  {
    id: 5,
    text: <FormattedMessage id="homeCard5Title" />,
    body: <FormattedMessage id="homeCard5Body" />,
    image: <StaticImage src="../../images/home/handle-tasks.png" alt="Heir " className="w-40" />,
    type: TYPECLIENT.uo,
    typePlaceholder: <FormattedMessage id="uo" />,
    cta_1: { text: <FormattedMessage id="homeCtaMoreInfo" />, url: '/uitvaart-ondernemer' }
  }
];

export { cards };
