import React from 'react';

import { classNames } from '../../../utils/helpers';

export interface TitleProps {
  color?: 'dark' | 'light' | 'plum';
  children: JSX.Element | string;
}

export default function Title({ children, color }: TitleProps) {
  const baseStyles = 'font-header text-4xl leading-snug lg:text-5xl';
  let colorStyles = '';
  switch (color) {
    case 'dark':
      colorStyles = 'text-blue';
      break;
    case 'light':
      colorStyles = 'text-offwhite';
      break;
    case 'plum':
      colorStyles = 'text-plum';
      break;
    default:
      colorStyles = 'text-blue';
      break;
  }
  return <h1 className={classNames(baseStyles, colorStyles)}>{children}</h1>;
}
