import React from 'react';

function VacaturesSEO() {
  return (
    <meta
      name="description"
      content="Solliciteer nu voor de nieuwste vacatures bij Closure en word deel van ons enthousiaste team. Bekijk onze beschikbare posities en solliciteer vandaag nog!"
    />
  );
}

function VacaturesSEOFRBE() {
  return (
    <meta
      name="description"
      content="Postulez dès maintenant pour les dernières offres d'emploi chez Closure et rejoignez notre équipe enthousiaste. Consultez nos postes disponibles et postulez dès aujourd'hui!"
    />
  );
}

export { VacaturesSEO, VacaturesSEOFRBE };
