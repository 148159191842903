const news = [
  {
    id: 13,
    outlet: 'Quote',
    title:
      "Opzegdienst Closure groeit keihard: 'We worden bij 1 op de 3 overlijdens in Nederland ingezet'",
    date: '26/09/2023',
    description:
      'Toen Quote hen in 2018 sprak had Closure drie pilotklanten en waren ze nog enigszins zoekende naar het verdienmodel.',
    link: 'https://www.quotenet.nl/zakelijk/a45313881/opzegdienst-closure-groei-graciella-van-vliet/',
    locale: 'nl'
  },
  {
    id: 14,
    outlet: 'AD',
    title:
      "Ook als hij vrij is, beantwoordt hij vragen van collega's: Jochem 'kloonde' zichzelf met computerversie",
    date: '14/08/2023',
    description:
      "Zelfs als Jochem er niet is, is hij zijn collega's behulpzaam met antwoorden op vragen waar ze zelf geen raad mee weten.",
    link: 'https://www.ad.nl/tech/ook-als-hij-vrij-is-beantwoordt-hij-vragen-van-collegas-jochem-kloonde-zichzelf-met-computerversie~ab6df92f/',
    locale: 'nl'
  },
  {
    id: 15,
    outlet: 'De Ondernemer',
    title:
      "Dankzij AI-chatbot duurt onboarding bij startup Closure week korter: 'Tool leert voortdurend bij'",
    date: '09/08/2023',
    description:
      'Wanneer een medewerker in dienst komt duurt het minimaal een week voordat hij kan beginnen. Met Jochem.ai kan een nieuwe collega direct starten en al zijn vragen stellen in deze chatbot.',
    link: 'https://www.deondernemer.nl/innovatie/technologie/closure-jochem-ai-klantenservice-onboarding-bart-verhaegh-graciella-van-vliet~4801361',
    locale: 'nl'
  },
  {
    id: 11,
    outlet: 'TellMeMore',
    title: 'AI Deathtech opzegservice Closure lanceert in België',
    date: '30/12/2022',
    description:
      'De Nederlandse DeathTech-opzegservice Closure breidt zijn activiteiten uit naar België.',
    link: 'https://tellmemore.media/a-i-deathtech-opzegservice-closure-lanceert-in-belgie/',
    locale: 'be_NL'
  },
  {
    id: 10,
    outlet: 'De Tijd',
    title: 'Nederlandse AI-assistent bij overlijden van start in België',
    date: '28/12/2022',
    description:
      'Met een technologische oplossing wil de Nederlandse start-up Closure een pak administratie uit de handen van nabestaanden nemen bij een overlijden.',
    link: 'https://www.tijd.be/ondernemen/technologie/nederlandse-ai-assistent-bij-overlijden-van-start-in-belgie/10437443.html',
    locale: 'be_NL'
  },
  {
    id: 9,
    outlet: 'Bloovi',
    title: 'Nederlandse AI deathtech opzegservice Closure lanceert in België',
    date: '1/12/2022',
    description:
      'De Nederlandse scale-up Closure, dat nabestaanden met behulp van machine learning ondersteunt bij het beëindigen of overzetten van doorlopende abonnementen, start zijn DeathTech opzegservice in België. De scale-up haalde hiervoor ook 1,5 miljoen euro groeigeld op.',
    link: 'https://www.bloovi.be/artikels/ondernemersnieuws/2022/nederlandse-ai-deathtech-opzegservice-closure-lanceert-in-belgie',
    locale: 'nl'
  },
  {
    id: 1,
    outlet: 'RTL Nieuws',
    title: "Abonnementen van een overledene stopzetten: 'Confronterend en stressvol'",
    date: '06/08/2022',
    description:
      "Van streamingdiensten tot maaltijdboxen en van panty's tot je OV-chipkaart: tegenwoordig is er voor alles wel een abonnement.",
    link: 'https://www.rtlnieuws.nl/editienl/artikel/5325224/uitvaart-abonnementen-opzeggen-overlijden-stress-rouw-verdriet',
    locale: 'nl'
  },
  {
    id: 2,
    outlet: 'Quote',
    title: 'Closure haalt €1,5 miljoen op om internationaal nabestaanden bij te staan',
    date: '05/08/2022',
    description:
      "'Gemiddeld hebben we zo'n dertig abonnementen per persoon.' Bij een overlijdensgeval springt Closure in de bres, zodat nabestaanden niet al die ongemakkelijke telefoontjes hoeven te plegen.",
    link: 'https://www.quotenet.nl/financien/investeren/a40506510/closure-15-miljoen-investering/',
    locale: 'nl'
  },
  {
    id: 12,
    outlet: 'AD',
    title:
      'Nabestaanden soms nog jaren bezig met opzeggen abonnementen overledenen, Graciëlla heeft de oplossing',
    date: '03/08/2022',
    description:
      'Nog maar vijf jaar geleden was het een omslachtig karwei, waarmee rouwende nabestaanden werden opgezadeld: het opzeggen van abonnementen die een overleden familielid in zijn leven had afgesloten. Dankzij Graciëlla van Vliet en haar bedrijf Closure kan dat nu veel gemakkelijker. Over een paar jaar is die rompslomp misschien wel helemaal verleden tijd.',
    link: 'https://www.ad.nl/geld/nabestaanden-soms-nog-jaren-bezig-met-opzeggen-abonnementen-overledenen-graciella-heeft-de-oplossing~aad86f2c/'
  },
  {
    id: 3,
    outlet: 'Linda',
    title: 'GRACIËLLA RUNT BEDRIJF DAT ABONNEMENTEN, CONTRACTEN EN ACCOUNTS VAN OVERLEDENEN OPZEGT',
    date: '17/07/2022',
    description:
      'Graciëlla van Vliet richtte vijf jaar geleden, samen met haar compagnon Chantal van der Velde, een bedrijf op dat de accounts, contracten en abonnementen van overledenen opzegt. Dankbaar werk, vertelt ze.',
    link: 'https://www.linda.nl/lifestyle/geld/graciella-bedrijf-abonnementen-contracten-accounts-overledenen/',
    locale: 'nl'
  },
  {
    id: 4,
    outlet: 'RTL Nieuws',
    title: "Opzegdienst die nabestaanden helpt wil uitbreiden: 'Ook op andere momenten handig'",
    date: '08/07/2022',
    description:
      'Techbedrijf Closure neemt nabestaanden een tijdrovende klus uit handen: het opzeggen van abonnementen en contracten van hun overleden dierbare.',
    link: 'https://www.rtlnieuws.nl/tech/artikel/5319759/overlijden-opzeggen-abonnement-contract-bank-verzekering-provider',
    locale: 'nl'
  },
  {
    id: 5,
    outlet: 'Emerce',
    title: '€1.5 miljoen voor A.I. opzegservice Closure',
    date: '05/07/2022',
    description:
      'Investeerders Borski fund en Rabobank investeren samen €1,5 miljoen in Closure. De Rotterdamse scale-up ontzorgt families na het overlijden van een dierbare door het beëindigen of overzetten van doorlopende abonnementen',
    link: 'https://www.emerce.nl/wire/15-miljoen-ai-opzegservice-closure',
    locale: 'nl'
  },
  {
    id: 6,
    outlet: 'De Ondernemer',
    title: 'Opzegservice Closure krijgt investering van 1,5 miljoen euro',
    date: '05/07/2022',
    description:
      'Een gemiddeld persoon in Nederland heeft meer dan dertig doorlopende contracten, rekeningen, abonnementen accounts of profielen. Bij een overlijden kan deze berg aan verplichtingen behoorlijk oplopen en blijken alle contracten en abonnementen behoorlijk lastig op te zeggen. Closure speelt daar handig op in en helpt bij de afhandeling daarvan. Dat levert de opzegservice nu een investering van 1,5 miljoen euro op.',
    link: 'https://www.deondernemer.nl/innovatie/technologie/opzegservice-closure-investering-van-rabobank-borski-fund~4016283',
    locale: 'nl'
  },
  {
    id: 7,
    outlet: 'M/T Sprout',
    title:
      'Opzegdienst Closure krijgt vers groeigeld: ‘1 op de 5 overlijdens wordt gemeld via ons’',
    date: '25/05/2022',
    description:
      'Graciëlla van Vliet heeft 1,5 miljoen euro opgehaald voor Closure, de dienst die na een overlijden contracten opzegt. Inmiddels loopt 1 op de 5 &apos;overlijdens&apos; in Nederland via Closure. Tijd om Europa in te gaan.',
    link: 'https://mtsprout.nl/groei/closure-abonnement-opzeggen-overlijden',
    locale: 'nl'
  },
  {
    id: 8,
    outlet: 'De Telegraaf',
    title: 'Nabestaanden ontzorgen',
    date: '18/12/2018',
    description:
      'Graciëlla van Vliet richtte vijf jaar geleden, samen met haar compagnon Chantal van der Velde, een bedrijf op dat de accounts, contracten en abonnementen van overledenen opzegt. Dankbaar werk, vertelt ze.',
    link: 'https://www.telegraaf.nl/financieel/2934628/nabestaanden-ontzorgen',
    locale: 'nl'
  }
];

// eslint-disable-next-line import/prefer-default-export
export { news };
