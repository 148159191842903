import axios from 'axios';
import { HeadProps } from 'gatsby';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';

import Layout from '../../components/Layout';
import ModalUnofficialPartnerInformation from '../../components/ModalUnofficialPartnerInformation';
import PartnerCard from '../../components/PartnerCard';
import Section from '../../components/Section';
import Title from '../../components/Typography/Title';
import { PartnersSEO, PartnersSEOFRBE } from '../../content/seo/partners';
import HeadParent from '../../utils/header';

interface IFeature {
  id: number;
  text: string;
}

export interface IPartner {
  id: number;
  name: string;
  img: string;
  url: string;
  type: 'official' | 'unofficial';
  features: IFeature[];
}

function Partners({ pageContext }) {
  const [hasMorePages, setHasMorePages] = useState(true);
  const [pageStart, setPageStart] = useState(1);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [query, setQuery] = useState<string>();
  const [error, setError] = useState({ error: false, msg: '' });
  const [isOpen, setIsOpen] = useState(false);
  const { locale } = pageContext;
  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_BASE_URL}/homepage/partners`, {
        params: { locale }
      })
      .then(res => {
        if (res.status === 200 && res.data) {
          setPartners(res.data.data);
          setPageStart(p => p + 1);
          if (res.data.next) {
            setHasMorePages(true);
          } else {
            setHasMorePages(false);
          }
        }
      })
      .catch(e => {
        setError({ error: true, msg: e.msg });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    if (pageStart > 1) {
      axios
        .get(`${process.env.GATSBY_API_BASE_URL}/homepage/partners`, {
          params: {
            q: query,
            page: pageStart,
            locale
          }
        })
        .then(res => {
          if (res.status === 200 && res.data) {
            setPartners(oldPage => [...oldPage, ...res.data.data]);
            if (res.data.meta.current_page <= res.data.meta.total) {
              setPageStart(pageStart + 1);
              setHasMorePages(true);
            } else {
              setHasMorePages(false);
            }
          }
        })
        .catch(e => {
          setError({ error: true, msg: e.msg });
        });
    }
  };

  const changeHandler = event => {
    setQuery(event.target.value);
    axios
      .get(`${process.env.GATSBY_API_BASE_URL}/homepage/partners`, {
        params: { q: event.target.value, locale }
      })
      .then(res => {
        if (res.status === 200 && res.data) {
          setPartners(res.data.data);
          setPageStart(2);
          if (res.data.meta.current_page < res.data.meta.total) {
            setHasMorePages(true);
          } else {
            setHasMorePages(false);
          }
        }
      })
      .catch(e => {
        setError({ error: true, msg: e.msg });
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  return (
    <Layout pageContext={pageContext} navColor="bg-offwhite" navTextColor="dark">
      <Section paddingBottom="pb-12 lg:pb-28" paddingTop="pt-8 lg:pt-28">
        <div className="mx-auto mb-12 max-w-xl">
          <div className="mb-8 space-y-4 lg:text-center">
            <Title color="dark">
              <FormattedMessage id="partnersHeaderTitle" />
            </Title>
            <p className="text-lg text-plum">
              <FormattedMessage id="partnersHeaderBody" />
            </p>
          </div>
          <div className="flex items-center space-x-8">
            <div className="w-full">
              <input
                type="email"
                name="email"
                id="email"
                className="w-full rounded-md border border-gray-400 p-3 shadow-sm sm:text-sm"
                placeholder="Search"
                onChange={debouncedChangeHandler}
              />
            </div>
          </div>
        </div>
        {partners && (
          <InfiniteScroll
            pageStart={pageStart}
            loadMore={getData}
            hasMore={hasMorePages}
            loader={
              <div className="loader" key={0}>
                <div className="mt-24 flex items-center justify-center">
                  <div className="flex h-14 w-14 animate-spin items-center justify-center rounded-full bg-gradient-to-tr from-primary to-secondary">
                    <div className="h-9 w-9 rounded-full bg-offwhite" />
                  </div>
                </div>
              </div>
            }
          >
            <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
              {partners.map(partner => (
                <PartnerCard key={partner.id} {...partner} modal={() => setIsOpen(true)} />
              ))}
            </div>
          </InfiniteScroll>
        )}
        {error.error && (
          <div className="mx-auto my-12 max-w-md text-center lg:mt-20">
            <div className="mb-4 text-xl font-medium">
              <FormattedMessage id="errorTitlePartners" />
            </div>
            <p className="mb-8 text-base text-plum">
              <FormattedMessage id="errorBodyPartners" />
            </p>
          </div>
        )}
        {!partners && (
          <div className="mx-auto my-12 max-w-md text-center lg:mt-20">
            <div className="mb-4 text-xl font-medium">
              <FormattedMessage id="partnersNoSearchResults" />
            </div>
            <p className="mb-8 text-base text-plum">
              <FormattedMessage id="partnersNoSearchResultsInput" />
            </p>
          </div>
        )}
      </Section>
      <ModalUnofficialPartnerInformation isOpen={isOpen} setIsOpen={setIsOpen} />
    </Layout>
  );
}

export default Partners;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  let pageTitle = '';
  switch (locale) {
    case 'nl-NL':
      pageTitle = 'Partners';
      break;
    case 'fr-BE':
      pageTitle = 'Partenaires';
      break;
    case 'nl-BE':
      pageTitle = 'Partners';
      break;
    default:
      pageTitle = 'Partners';
      break;
  }
  return (
    <HeadParent pageTitle={pageTitle} location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <PartnersSEO />}
      {locale === 'fr-BE' && <PartnersSEOFRBE />}
    </HeadParent>
  );
}
