import React from 'react';
import { FormattedMessage } from 'react-intl';

import { classNames } from '../../utils/helpers';

export interface PartnerCardFeaturesProp {
  text: string;
  id: number;
}

export interface PartnerCardProp {
  type: 'official' | 'unofficial';
  name: string;
  img: string;
  features: PartnerCardFeaturesProp[];
  modal?: Function;
  url?: string;
}

export default function PartnerCard({ type, img, features, modal, name, url }: PartnerCardProp) {
  return (
    <div>
      <div
        className={classNames('bg-white p-8', type === 'official' ? 'rounded-md' : 'rounded-t-md')}
      >
        {img && (
          <div className="mb-4 flex h-14 w-24 items-center">
            <img src={img} className="max-h-full max-w-full" alt={name} />
          </div>
        )}
        {img === null && (
          <div className="mb-4 flex h-14 w-24 items-center rounded-lg bg-gray-100" />
        )}
        <div className="mb-4 flex items-center justify-start space-x-2">
          <div className="text-lg font-medium text-blue">{name}</div>
        </div>
        <div className="h-20 space-y-2">
          {features.map(feature => (
            <div key={feature.id} className="flex items-center justify-start space-x-4">
              <div className="h-2 w-2 rounded-full bg-bordergray" />
              <div className="text-sm text-plum">{feature.text}</div>
            </div>
          ))}
        </div>
      </div>
      {type === 'official' && (
        <a href={url}>
          <button
            type="button"
            className="w-full rounded-b-md bg-darkblue py-3 text-center text-sm text-offwhite"
          >
            <FormattedMessage id="partnersCardButtonBegin" />
          </button>
        </a>
      )}
      {type !== 'official' && modal && (
        <button
          type="button"
          className="w-full rounded-b-md bg-bordergray py-3 text-center text-sm text-offwhite"
          onClick={() => modal()}
        >
          <FormattedMessage id="partnersCardButtonMoreInfo" />
        </button>
      )}
    </div>
  );
}
