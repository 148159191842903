import { HeadProps } from 'gatsby';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import CaseStudyCard from '../../components/CaseStudyCard';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { caseStudies } from '../../content/data/caseStudies';
import { CaseStudySEO, CaseStudySEOFRRE } from '../../content/seo/case-studies';
import HeadParent from '../../utils/header';

function CaseStudies({ pageContext }) {
  return (
    <Layout pageContext={pageContext} navTextColor="white" navColor="bg-primary">
      <Section paddingTop="lg:pt-28" paddingBottom="pb-12 lg:pb-28" bgColor="bg-primary">
        <div className="max-w-2xl py-8 lg:py-12">
          <div className="font-header text-5xl leading-normal text-darkwhite">
            <FormattedMessage id="caseStudiesHeaderTitle" />
          </div>
          <p className="text-lg text-offwhite">
            <FormattedMessage id="caseStudiesHeaderBody" />
          </p>
        </div>
      </Section>
      <Section paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28" bgColor="bg-offwhite">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
          {caseStudies.map(study => (
            <CaseStudyCard key={study.id} {...study} />
          ))}
        </div>
      </Section>
    </Layout>
  );
}

export default CaseStudies;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  let pageTitle = '';
  switch (locale) {
    case 'nl-NL':
      pageTitle = 'Case Studies';
      break;
    case 'fr-BE':
      pageTitle = 'Études de cas';
      break;
    case 'nl-BE':
      pageTitle = 'Case Studies';
      break;
    default:
      pageTitle = 'Case Studies';
      break;
  }
  return (
    <HeadParent pageTitle={pageTitle} location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <CaseStudySEO />}
      {locale === 'fr-BE' && <CaseStudySEOFRRE />}
    </HeadParent>
  );
}
