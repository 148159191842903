import React from 'react';

function CaseStudySEO() {
  return (
    <meta
      name="description"
      content="Closure werkt al met meer dan 25 grote organisaties in Nederland. Hier vind je een aantal casestudies die wij samen met onze partners hebben gemaakt."
    />
  );
}

function CaseStudySEOFRRE() {
  return (
    <meta
      name="description"
      content="Closure travaille déjà avec plus de 25 grandes organisations aux Pays-Bas. Vous trouverez ici un certain nombre d'études de cas que nous avons réalisées avec nos partenaires."
    />
  );
}

export { CaseStudySEO, CaseStudySEOFRRE };
