import * as messagesEN from './en-us';
import * as messagesNL from './nl';
import * as messagesBENL from './nl-be';
import * as messagesBEFR from './fr-be';

const LOCALES = {
  DUTCH: 'nl-NL',
  ENGLISH: 'en-US',
  BELGIUM_DUTCH: 'nl-BE',
  BELGIUM_FRENCH: 'fr-BE'
};

const MESSAGES = {
  [LOCALES.ENGLISH]: messagesEN.default,
  [LOCALES.DUTCH]: messagesNL.default,
  [LOCALES.BELGIUM_DUTCH]: messagesBENL.default,
  [LOCALES.BELGIUM_FRENCH]: messagesBEFR.default
};

export { MESSAGES, LOCALES };
