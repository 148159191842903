import React from 'react';

function TeamSEO() {
  return (
    <meta
      name="description"
      content="Een ambitieus en jong team die bezig zijn om key moments in het leven van mensen gemakkelijker en efficienter door te geven aan alle organisaties."
    />
  );
}

function TeamSEOFRBE() {
  return (
    <meta
      name="description"
      content="Une équipe ambitieuse et jeune qui travaille à transmettre plus facilement et efficacement les moments clés de la vie des gens à toutes les organisations."
    />
  );
}

export { TeamSEO, TeamSEOFRBE };
