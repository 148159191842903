import { StaticImage } from 'gatsby-plugin-image';
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const caseStudies = [
  {
    id: 1,
    company: 'Odido',
    img: (
      <StaticImage
        src="../../images/case-studies/t-mobile/t-mobile-header.png"
        className="rounded-t-lg"
        alt="T-Mobile logo header"
      />
    ),
    description: <FormattedMessage id="tMobileCaseStudySummary" />,
    url: 'case-studies/t-mobile',
    cases: '20.000+'
  },
  {
    id: 3,
    company: 'OV Chipkaart',
    img: (
      <StaticImage
        src="../../images/case-studies/ov-chipkaart/ov-chipkaart-header.png"
        className="rounded-t-lg"
        alt="DPG Media logo header"
      />
    ),
    description: <FormattedMessage id="ovChipkaartCaseStudySummary" />,
    url: 'case-studies/ov-chipkaart',
    cases: '10.000+'
  }
];

export { caseStudies };
