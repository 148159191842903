import { HeadProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import CheckBadgeIcon from '@heroicons/react/24/outline/CheckBadgeIcon';

import Button from '../../components/Button';
import FooterCTA from '../../components/FooterCTA';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import TeamCard from '../../components/TeamCard';
import Title from '../../components/Typography/Title';
import { teamMembers } from '../../content/data/team';
import {TeamSEO, TeamSEOFRBE} from '../../content/seo/team';
import { ReactComponent as GoldmanSachs } from '../../images/svg/goldman_sachs.svg';
import HeadParent from '../../utils/header';

function Team({ pageContext }) {
  return (
    <Layout pageContext={pageContext} navColor="white" navTextColor="dark">
      <Section paddingTop="pt-4 lg:pt-12">
        <div className="rounded-2xl bg-primary px-8 py-24 lg:px-10 lg:py-40">
          <div className="bg-ligature-st pointer-events-none absolute inset-0 bg-abstract bg-cover bg-center bg-no-repeat opacity-10" />
          <div className="mx-auto max-w-3xl text-left lg:text-center">
            <div className="text-left text-lg font-medium text-offwhite lg:text-center">
              <FormattedMessage id="teamHeaderCategory" />
            </div>
            <div className="mt-8 text-left font-header text-4xl leading-snug text-darkwhite lg:text-center">
              <FormattedMessage id="teamHeaderTitle" />
            </div>
          </div>
        </div>
      </Section>
      <Section paddingTop="pt-8 lg:pt-12" paddingBottom="pb-16 lg:pb-28">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-5 lg:gap-12">
          <div className="col-span-3 space-y-4 rounded-xl bg-darkwhite p-6 lg:p-14">
            <div className="text-lg font-medium text-plum">
              <FormattedMessage id="teamOurStoryCategory" />
            </div>
            <div className="font-header text-4xl text-blue">
              <FormattedMessage id="teamOurStoryTitle" />
            </div>
            <p className="text-base text-plum">
              <FormattedMessage id="teamOurStoryBody" />
            </p>
          </div>
          <div className="col-span-3 space-y-8 rounded-lg bg-darkwhite p-6 lg:col-span-2 lg:space-y-12 lg:p-14">
            <div className="">
              <div className="mb-4 font-header text-5xl text-blue">
                <FormattedMessage id="teamStat1Value" />
              </div>
              <p className="text-sm text-plum">
                <FormattedMessage id="teamStat1Title" />
              </p>
            </div>
            <div className="">
              <div className="mb-4 font-header text-5xl text-blue">
                <FormattedMessage id="teamStat2Value" />
              </div>
              <p className="text-sm text-plum">
                <FormattedMessage id="teamStat2Title" />
              </p>
            </div>
            <div className="">
              <div className="mb-4 font-header text-5xl text-blue">
                <GoldmanSachs className="h-16" />
              </div>
              <p className="text-sm text-plum">
                <FormattedMessage id="teamStat3Title" />
              </p>
            </div>
          </div>
        </div>
      </Section>
      <Section paddingBottom="pb-12 lg:pb-28">
        <div className="mx-auto mb-8 max-w-2xl text-center lg:mb-16">
          <Title color="dark">
            <FormattedMessage id="teamSectionTitle" />
          </Title>
        </div>
        <div className="mx-auto grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-8">
          {teamMembers.map(member => (
            <TeamCard key={member.id} {...member} />
          ))}
        </div>
      </Section>
      <Section paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28" bgColor="bg-darkwhite">
        <div className="mx-auto mb-8 max-w-3xl font-header text-4xl text-blue lg:mb-16 lg:text-center">
          <FormattedMessage id="teamSectionPartnersProgram" />
        </div>
        <div className="mx-auto grid max-w-6xl grid-cols-2 gap-4 lg:grid-cols-5">
          <div className="flex items-center justify-center rounded-xl bg-darkwhite-700 px-14">
            <StaticImage src="../../images/partners/TEDx.png" alt="partner" />
          </div>
          <div className="rounded-xl bg-darkwhite-700 p-6">
            <StaticImage src="../../images/partners/phillips.png" alt="partner" />
          </div>
          <div className="rounded-xl bg-darkwhite-700 p-6">
            <StaticImage src="../../images/partners/Adyen.png" alt="partner" />
          </div>
          <div className="rounded-xl bg-darkwhite-700 p-6">
            <StaticImage src="../../images/partners/techleap.png" alt="partner" />
          </div>
          <div className="rounded-xl bg-darkwhite-700 p-6">
            <StaticImage src="../../images/partners/utrecht-inc.png" alt="partner" />
          </div>
        </div>
      </Section>
      <Section paddingBottom="pb-12 lg:pb-28" paddingTop="pt-12 lg:pt-28">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="overflow-hidden rounded-t-lg lg:rounded-l-xl lg:rounded-tr-none">
            <StaticImage
              src="../../images/team/group.jpg"
              alt="closure team"
              placeholder="none"
              className="h-full w-full overflow-hidden rounded-t-lg object-cover lg:rounded-l-xl lg:rounded-tr-none"
            />
          </div>
          <div className="space-y-6 rounded-b-lg bg-blue py-14 px-10 text-darkwhite lg:rounded-l-none lg:rounded-r-xl lg:py-28 lg:px-20">
            <Title color="light">
              <FormattedMessage id="teamHiringTitle" />
            </Title>
            <p className="max-w-sm text-lg text-darkwhite">
              <FormattedMessage id="teamHiringBody" />
            </p>
            <ul className="mb-2 space-y-3 text-offwhite">
              <li>
                <div className="flex flex-row items-center">
                  <CheckBadgeIcon className="mr-2 w-4" />
                  <span className="text-base">
                    <FormattedMessage id="teamHiringFeature1" />
                  </span>
                </div>
              </li>
              <li>
                <div className="flex flex-row items-center">
                  <CheckBadgeIcon className="min-w-4 mr-2 w-4" />
                  <span className="text-base">
                    <FormattedMessage id="teamHiringFeature2" />
                  </span>
                </div>
              </li>
              <li>
                <div className="flex flex-row items-center">
                  <CheckBadgeIcon className="mr-2 w-4" />
                  <span className="text-base">
                    <FormattedMessage id="teamHiringFeature3" />
                  </span>
                </div>
              </li>
            </ul>
            <div className="mt-2">
              <Button
                type="primary"
                color="white"
                link="https://www.linkedin.com/company/closure_services/jobs/"
                variant="external"
              >
                <FormattedMessage id="teamHiringButton" />
              </Button>
            </div>
          </div>
        </div>
      </Section>
      <FooterCTA bgColor="bg-darkwhite" bgInsideColor="bg-darkwhite" />
    </Layout>
  );
}

export default Team;

export function Head(props: HeadProps) {
  const { location } = props;
  const { pageContext } = props;
  const { locale } = pageContext;
  let pageTitle = '';
  switch (locale) {
    case 'nl-NL':
      pageTitle = 'Team';
      break;
    case 'fr-BE':
      pageTitle = 'Équipe';
      break;
    case 'nl-BE':
      pageTitle = 'Team';
      break;
    default:
      pageTitle = 'Team';
      break;
  }
  return (
    <HeadParent pageTitle={pageTitle} location={location}>
      {(locale === 'nl-NL' || locale === 'nl-BE') && <TeamSEO />}
      {locale === 'fr-BE' && <TeamSEOFRBE />}
    </HeadParent>
  );
}
